import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, GridItem, Image, Spacer, Text, Grid } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { ButtonCustom } from '../../chakra-ui/Button/ButtonCustom'
import { CollectionType } from '../../models/CollectionType'
import { getCollectionPublicApi } from '../../setup/api/apiCollectionTrending'
import { Link } from 'react-router-dom'
import { SUPPORT_NETWORK, URL_COLLECTION_DEFAULT } from '../../constants'
import { WalletContext } from '../../app/wallet/walletContext'
import { fetchUserProfile } from '../ProfilePage/userProfileSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'

export const StakingListTopPage = () => {
    const intl = useIntl()
    const { isLogin, tokenExpiredCb } = useContext(WalletContext)
    const dispatch = useAppDispatch()
    const { data: userData } = useAppSelector((state) => state.userProfile)
    const [collectionsStaking, setCollectionsStaking] = useState<CollectionType[]>()
    useEffect(() => {
        (async () => {
            const resp = await getCollectionPublicApi({ take: 10, isStaking: true, network: SUPPORT_NETWORK, walletAddress: userData?.walletAddress })
            setCollectionsStaking(resp?.data)
        })()

    }, [userData?.walletAddress])

    useEffect(() => {
        if (!isLogin) return
        dispatch(fetchUserProfile(tokenExpiredCb))

    }, [isLogin])

    return (
        <>
            {collectionsStaking && collectionsStaking?.length > 0 &&
                <Box w={'100%'} mt={{ base: '40px', lg: '80px' }}>
                    <Flex w={'100%'} >
                        <Box>
                            <Text fontSize={{ lg: '32px', base: '24px' }} fontWeight={700}>{intl.formatMessage({ id: 'HOME.STAKING.LIST' })}</Text>
                        </Box>
                        <Spacer />
                        <Box
                            cursor={'pointer'}
                            fontSize={{ lg: '18px', base: '16px' }}
                            fontWeight={600}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            borderRadius={'6px'}
                            h={{ base: '40px', lg: '48px' }}
                            px={{ base: '16px', lg: '24px' }}
                            backgroundColor={'#FFFFFF0A'}>
                            <Link to={`/collection/staking`}>
                                {intl.formatMessage({ id: 'ACTION.VIEW.MORE' })}
                            </Link>
                        </Box>
                    </Flex>
                    <Box overflow="auto" mt={{ base: '24px', lg: '40px' }}>
                        <Flex justify={{ base: 'start', lg: 'space-between' }} >
                            <Box w={{ lg: '47%', base: '100%' }}>
                                <Flex flexWrap={'wrap'} w={'100%'}>
                                    {collectionsStaking?.slice(0, 5)?.map((s, i) =>
                                        <Flex key={i} px={{ base: '6px', lg: '16px' }} py={{ base: '12px', lg: '16px' }} w={'100%'}>
                                            <Box key={i} w={'70%'}>
                                                <Flex w={'100%'} align={'center'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                    <Image borderRadius={'6px'} h={'56px'} w={'56px'} src={s?.logo?.includes('ipfs.io') ? s?.logo?.replace('ipfs.io', 'cloudflare-ipfs.com') : s?.logo ?? URL_COLLECTION_DEFAULT} />
                                                    <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} ml={{ base: '12px', lg: '16px' }} fontWeight={'700'} fontSize={'16px'} w={{ base: '210px', lg: 'auto' }} color={'#FFFFFF'}>{s?.name}</Text>
                                                </Flex>
                                            </Box>
                                            <Box w={'30%'} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                                                <Box>
                                                    <ButtonCustom href={`/staking/${s?.network}/${s?.contractAddress}`} textButton={intl.formatMessage({ id: 'BUTTON.STACKING' })} fontSizeButton={{ lg: '16px', base: '14px' }} heightButton={{ base: '32px', lg: '40px' }} />
                                                </Box>
                                            </Box>
                                        </Flex>
                                    )}
                                </Flex>
                            </Box>
                            <Box ml={{ base: '28px', lg: 0 }} w={{ lg: '47%', base: '100%' }}>
                                <Flex flexWrap={'wrap'} w={'100%'}>
                                    {collectionsStaking?.slice(5)?.map((s, i) =>
                                        <Flex key={i} px={{ base: '6px', lg: '16px' }} py={{ base: '12px', lg: '16px' }} w={'100%'}>
                                            <Box key={i} w={'70%'}>
                                                <Flex w={'100%'} align={'center'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                    <Image borderRadius={'6px'} h={'56px'} w={'56px'} src={s?.logo?.includes('ipfs.io') ? s?.logo?.replace('ipfs.io', 'cloudflare-ipfs.com') : s?.logo ?? URL_COLLECTION_DEFAULT} />
                                                    <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} ml={{ base: '12px', lg: '16px' }} fontWeight={'700'} fontSize={'16px'} w={{ base: '210px', lg: 'auto' }} color={'#FFFFFF'}>{s?.name}</Text>
                                                </Flex>
                                            </Box>
                                            <Box w={'30%'} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                                                <Box>
                                                    <ButtonCustom href={`/staking/${s?.network}/${s?.contractAddress}`} textButton={intl.formatMessage({ id: 'BUTTON.STACKING' })} fontSizeButton={{ lg: '16px', base: '14px' }} heightButton={{ base: '32px', lg: '40px' }} />
                                                </Box>
                                            </Box>
                                        </Flex>
                                    )}
                                </Flex>
                            </Box>

                        </Flex>
                    </Box>

                </Box>
            }
        </>
    )
}
