import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Api from '../../setup/axios/SetupAxios'
import { COLLECTION_SERVICE } from '../../constants'
import { CollectionType } from '../../models/CollectionType'

type CollectionReq = {
    walletAddress: string
    name?: string
    isAll?: boolean
    network?: string
}

const getCollection = (data: CollectionReq) => Api.CollectionService.GET(COLLECTION_SERVICE.GET_COLLECTION_PUBLIC, { params: { ...data } })

export const getUserCollection = createAsyncThunk('collection/getUserCollection', async (req: CollectionReq) => {
    const resp = await getCollection(req)
    return resp?.data?.data
})

type InitialState = {
    loading: boolean
    data: CollectionType[] | null
    errors: any
}

const initialState: InitialState = {
    loading: false,
    data: null,
    errors: null,
}

const userCollectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserCollection.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserCollection.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
        })
        builder.addCase(getUserCollection.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.errors = action?.error?.message || 'Something went wrong'
        })
    },
})

export default userCollectionSlice.reducer
