import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import Api from '../../setup/axios/SetupAxios';
import { AUTH_SERVICE } from '../../constants';
import { User, UserUpdateReq } from '../../models/UserModel';

const getUser = () => Api.AuthService.GET(AUTH_SERVICE.GET_USER);

const updateUser = async (data: UserUpdateReq, tokenExpiredCb?: () => void) => {
    const formData = new FormData();
    data.userName !== null && formData.append('fullname', data.userName ? data.userName.toString() : '')
    data.email !== null && formData.append('email', data.email ? data.email.toString() : 'null')
    data.bio !== null && formData.append('bio', data.bio ? data.bio.toString() : '')
    typeof data.avatar !== 'string' && formData.append('avatar', data.avatar)
    typeof data.banner !== 'string' && formData.append('banner', data.banner)
    const resp = await Api.AuthService.POST(AUTH_SERVICE.UPDATE_USER, formData)
    return resp
}

export const fetchUserProfile = createAsyncThunk('profile/fetchUserProfile', async (tokenExpiredCb?: any) => {
    const resp = await getUser();
    if (resp?.errors?.[0]?.code === 4141) {
        tokenExpiredCb?.();
    }
    return resp?.data;
});

export const updateUserProfile = createAsyncThunk('profile/updateUserProfile', async ({ data, tokenExpiredCb }: { data: UserUpdateReq, tokenExpiredCb?: () => void }) => {
    const resp = await updateUser(data, tokenExpiredCb);
    if (resp?.errors?.[0]?.code === 4141) {
        tokenExpiredCb?.();
    }
    return resp
});

export const resetUserProfile = createAction('profile/resetUserProfile')

type InitialState = {
    loading: boolean;
    data: User | null;
    errors: any;
};

const initialState: InitialState = {
    loading: false,
    data: null,
    errors: null,
};

const userProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.errors = action?.error?.message || 'Something went wrong';
        });
        builder.addCase(updateUserProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.data?.data
        });
        builder.addCase(updateUserProfile.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.errors = action?.error?.message || 'Something went wrong';
        });
        builder.addCase(resetUserProfile, (state, action) => {
            return initialState
        })
    },
});

export default userProfileSlice.reducer;
