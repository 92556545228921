import React from 'react'
import { ConnectRoutes } from './ConnectRoutes'
import { ThirdwebProvider, metamaskWallet, walletConnect } from '@thirdweb-dev/react'
import { WalletProvider } from '../wallet/walletContext';
import { Ethereum, Polygon, Mumbai, PolygonAmoyTestnet } from "@thirdweb-dev/chains";
import { setLocale } from 'yup'
import { CLIENDT_ID, I18N_CONFIG_KEY } from '../../constants';
import { locales } from '../../assets/ts/_utils/YupHelpers/yupLocales';

const selectedLange = JSON?.parse(localStorage.getItem(I18N_CONFIG_KEY)!)?.selectedLang || 'en'

setLocale(selectedLange !== 'ja' ? {} : locales?.[selectedLange])

export const AppRoutes = () => {
    return (
        <>
            <ThirdwebProvider
                autoConnectTimeout={30000}
                supportedChains={[Ethereum, Polygon, Mumbai, PolygonAmoyTestnet]}
                activeChain={PolygonAmoyTestnet}
                clientId={CLIENDT_ID}
                supportedWallets={[
                    metamaskWallet({
                        recommended: true,
                    }),
                    walletConnect(),
                ]}
            >
                <WalletProvider >
                    <ConnectRoutes />
                </WalletProvider>
            </ThirdwebProvider>
        </>
    )
}
