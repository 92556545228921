import { Box, Button, CircularProgress, Container, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { fetchUserProfile } from '../../components/ProfilePage/userProfileSlice'
import { useParams } from 'react-router-dom'
import { WalletContext } from '../wallet/walletContext'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { StakingListType, StatisticsStakingType } from '../../models/StakingType'
import { claimRewardApi, getStakingListApi, getStatisticsStakingApi } from '../../setup/api/apiStaking'
import { StakingUtils } from '../wallet/stakingUtils'
import { STAKING_CONTRACT_ADDRESS, USDT_CURRENCY, USDT_DECIMALS, chainIds } from '../../constants'
import { ethers } from 'ethers'
import { NftDetailType, StakingHistory } from '../../models/NftType'
import { getNftDetailApi } from '../../setup/api/apiNftDetail'
import { useChainId, useSigner, useSwitchChain } from '@thirdweb-dev/react'
import { toast } from 'react-toastify'
import { formatNumber, formatPositiveNumber } from '../../helpers/crud-helper/helpers'

const getHighestStaking = (stakingHistory: StakingHistory[]): StakingHistory | undefined => {
    let highestStaking: StakingHistory | undefined = undefined;
    if (stakingHistory.length === 0) return highestStaking;

    highestStaking = stakingHistory[0]

    for (const item of stakingHistory) {
        if (!highestStaking.stakingId || item.stakingId > highestStaking.stakingId) {
            highestStaking = item;
        }
    }

    return highestStaking;
};



export const NftStakingDetailPage = () => {
    const intl = useIntl()
    const { isLogin, tokenExpiredCb } = useContext(WalletContext)
    const { network, contractId, tokenId } = useParams()
    const dispatch = useAppDispatch()

    const { data: userData } = useAppSelector((state) => state.userProfile)

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!network || !contractId || !isLogin) return
        dispatch(fetchUserProfile(tokenExpiredCb))

    }, [network, contractId, isLogin])

    const [statictisticsDataStaking, setStatictisticsDataStaking] = useState<StatisticsStakingType>()

    useEffect(() => {
        if (!isLogin || !userData?.walletAddress || !network) return
        (async () => {
            const statistics = await getStatisticsStakingApi(network)

            setStatictisticsDataStaking(statistics)
        })()

    }, [isLogin, userData?.walletAddress])

    const [reloadData, setReloadData] = useState(false)

    const [nftInformation, setNftInformation] = useState<NftDetailType>()

    useEffect(() => {
        if (!network || !contractId || !tokenId) return
        (async () => {
            const res = await getNftDetailApi({
                network: network,
                contractAddress: contractId,
                tokenId: tokenId
            })

            setNftInformation(res)

        })()
    }, [network, contractId, tokenId])

    const [stakingList, setStakingList] = useState<StakingListType[]>()

    useEffect(() => {
        if (!network || !contractId || !tokenId || !isLogin) return
        (async () => {
            const stakingList = await getStakingListApi(
                {
                    network: network,
                    contractAddress: contractId,
                    tokenId: tokenId
                }
            )
            setStakingList(stakingList)
        })()
    }, [network, contractId, tokenId, isLogin, reloadData])


    const [claimableReward, setClaimableReward] = useState<any>()
    const [loadingClaim, setLoadingClaim] = useState(false)

    useEffect(() => {
        if (!network) return
        const highestStaking: StakingHistory | undefined = nftInformation?.stakingHistory ? getHighestStaking(nftInformation.stakingHistory) : undefined;
        if (!highestStaking) return
        (async () => {
            try {
                const claimableReward = await StakingUtils?.getClaimableReward(network, STAKING_CONTRACT_ADDRESS, ethers.BigNumber.from(highestStaking?.stakingId))
                const number = ethers.BigNumber.from(claimableReward)
                const formattedReward = ethers.utils.formatUnits(number, USDT_DECIMALS)
                setClaimableReward(formattedReward)
            } catch (error) {
                console?.log('error', error)
            }
        })()
    }, [nftInformation, reloadData])

    const chain = useChainId()
    const switchChain = useSwitchChain()
    const signer = useSigner()


    const handleClaim = async () => {
        if (!nftInformation) return
        const highestStaking: StakingHistory | undefined = nftInformation?.stakingHistory ? getHighestStaking(nftInformation.stakingHistory) : undefined;
        if (!highestStaking) return
        setLoadingClaim(true)

        try {
            if (chainIds[nftInformation?.collection?.network] !== chain) {
                await switchChain(chainIds[nftInformation?.collection?.network])
            }
            const result = await StakingUtils.claimReward(signer as any, STAKING_CONTRACT_ADDRESS, ethers.BigNumber.from(highestStaking?.stakingId))


            if (result?.receipt?.status) {
                const reward = Number(ethers.utils.formatUnits(ethers.BigNumber.from(result?.receipt?.events?.[1]?.args?.[4]), USDT_DECIMALS))
                await claimRewardApi({
                    network: network,
                    contractAddress: nftInformation?.contractAddress,
                    tokenId: nftInformation?.tokenId,
                    transactionHash: result?.receipt.transactionHash,
                    reward
                })
                toast?.success(intl.formatMessage({ id: 'NFT.CLAIM.REWARD.SUCCESS' }), { closeButton: false, })
                setLoadingClaim(false)
                setLoadingClaim(false)
                setReloadData(!reloadData)
            }
        } catch (error: any) {
            setLoadingClaim(false)
            setLoadingClaim(false)
            if (error?.reason) {
                toast?.error(error?.reason || 'unknown error')
                return
            }
        }
    }

    return (
        <Container maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0}>
            {isLogin &&
                <>
                    <Flex flexDirection={{ base: 'column', lg: 'row' }} borderRadius={'6px'} justify={'center'} align={'center'} backgroundColor={'#FFFFFF0A'} px={{ base: '24px', lg: '32px' }} py={{ base: '12px', lg: '32px' }} flexWrap={'wrap'}>
                        <Box w={'100%'} flex="1" borderRight={{ base: 'none', lg: '1px solid #27282A' }} borderBottom={{ lg: 'none', base: '1px solid #27282A' }}>
                            <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'NFT.STAKING.TOTAL.NFT.PRICE' })}</Text>
                                <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{!statictisticsDataStaking ? <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='gray.500' /> : statictisticsDataStaking?.totalStakingPrice || 0}</Text>
                            </Box>
                        </Box>
                        <Box w={'100%'} flex="1" borderRight={{ base: 'none', lg: '1px solid #27282A' }} borderBottom={{ lg: 'none', base: '1px solid #27282A' }}>
                            <Box py={{ base: '16px', lg: 0 }} display={'flex'} justifyContent={{ base: 'space-between', lg: 'center' }} alignItems={'center'}>
                                <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                                    <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'NFT.STAKING.ESTIMATED.REWARDS' })}</Text>
                                    <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{formatNumber(Number(claimableReward), 0.01) || 0} {USDT_CURRENCY}</Text>
                                </Box>
                                <Button
                                    onClick={handleClaim}
                                    as={'button'}
                                    color='#FFFFFF'
                                    cursor={'pointer'}
                                    rounded="6px"
                                    fontSize={{ base: '18px', lg: '20px' }}
                                    fontWeight='600'
                                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                    bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                                    p={'2px'}
                                    height={{ base: '48px', lg: '56px' }}
                                    _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                                    _active={{
                                        bg: '#dddfe2',
                                        transform: 'scale(0.98)',
                                        borderColor: '#bec3c9',
                                    }}
                                    isDisabled={!Number(claimableReward) || loadingClaim || !nftInformation?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string)}
                                >
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg="#131619" rounded="4px">
                                        <Box color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                            {intl.formatMessage({ id: 'NFT.CLAIM' })}
                                            {loadingClaim && <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='green.700' />}
                                        </Box>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                        <Box w={'100%'} flex="1">
                            <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                                <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'NFT.STAKING.APR' }, { apy: statictisticsDataStaking ? formatPositiveNumber(statictisticsDataStaking?.apy / 12) : 0 })}</Text>
                                <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{statictisticsDataStaking ? formatPositiveNumber(statictisticsDataStaking?.apy) : 0} %</Text>
                            </Box>
                        </Box>
                    </Flex>

                    <Box w={'100%'} mt={{ base: '24px', lg: '40px' }}>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th px={{ base: '28px', lg: '16px' }} color={'#515458'} textAlign={'center'} w={{ base: '0%', lg: '25%' }} >{intl.formatMessage({ id: 'NFT.TABLE.START_MONTH' })}</Th>
                                        <Th textAlign={'end'} px={{ base: '28px', lg: '40px' }} color={'#515458'} w={{ base: '33%', lg: '25%' }} >{intl.formatMessage({ id: 'NFT.TABLE.TOTAL_PRICE' })}</Th>
                                        <Th textAlign={'end'} px={{ base: '28px', lg: '40px' }} color={'#515458'} w={{ base: '33%', lg: '25%' }} >{intl.formatMessage({ id: 'NFT.TABLE.ESTIMATED_REWARDS' })}</Th>
                                        <Th textAlign={'end'} px={{ base: '28px', lg: '40px' }} color={'#515458'} w={{ base: '33%', lg: '25%' }} >{intl.formatMessage({ id: 'NFT.TABLE.APR' })}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {stakingList && stakingList?.length > 0 ? stakingList?.map(((c, i: number) =>
                                        <Tr cursor="pointer" _hover={{ bg: '#FFFFFF0A' }} fontSize={'16px'} key={i}>
                                            <Td px={{ base: '28px', lg: '40px' }} py={{ base: '28px', lg: '28px' }} textAlign={'center'}>{c?.year} / {c?.month}</Td>
                                            <Td px={{ base: '28px', lg: '40px' }} py={{ base: '28px', lg: '28px' }}  >
                                                <Flex justifyContent={'end'}>
                                                    <Text>{c?.totalStakingPrice}</Text>
                                                    <Text fontWeight={700} ml={'5px'} color={'#808489'}>{c?.currency}</Text>
                                                </Flex>
                                            </Td>
                                            <Td px={{ base: '28px', lg: '40px' }} py={{ base: '28px', lg: '28px' }} >
                                                <Flex justifyContent={'end'}>
                                                    <Text>{formatNumber(Number(c?.claimableRewards), 0.01) || 0}</Text>
                                                    <Text fontWeight={700} ml={'5px'} color={'#808489'}>{c?.currency || USDT_CURRENCY}</Text>
                                                </Flex>
                                            </Td>
                                            <Td px={{ base: '28px', lg: '40px' }} py={{ base: '28px', lg: '28px' }} textAlign={'end'}>{formatPositiveNumber(c?.apy)}%</Td>
                                        </Tr>
                                    ))
                                        :
                                        <Tr>
                                            <Td colSpan={4} w={'100%'}>
                                                <Flex align={'center'} justifyContent={'center'}>
                                                    {stakingList ? intl.formatMessage({ id: 'TABLE.NOMATCHING.RECORDFOUND.STAKING' }) : <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='gray.500' />}
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            }
        </Container>
    )
}
