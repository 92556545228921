import { Box, Image, Text, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { CollectionType } from '../../models/CollectionType'
import { formatNumber } from '../../helpers/crud-helper/helpers'
import { URL_COLLECTION_DEFAULT } from '../../constants'


type Props = {
    data: CollectionType
    layout: string
    staking?: boolean
}

export enum CardLayout {
    COZY = 'cozy',
    COMPACT = 'compact',
}

export const CardCollection: FC<Props> = ({ staking, data, layout = CardLayout.COZY }) => {
    const intl = useIntl()

    const cardSize: any = {
        [CardLayout.COZY]: { base: 'calc(50% - 6px)', lg: 'calc(25% - 24px)' },
        [CardLayout.COMPACT]: { base: 'calc(50% - 6px)', lg: 'calc(20% - 25.6px)' },
    }
    const imgHeight: any = {
        [CardLayout.COZY]: { base: '173px', lg: '304px' },
        [CardLayout.COMPACT]: { base: '173px', lg: '230px' },
    }
    const updatedImageSrc = data?.logo?.includes('ipfs.io') ? data?.logo?.replace('ipfs.io', 'cloudflare-ipfs.com') : data?.logo ?? URL_COLLECTION_DEFAULT

    return (
        <Box borderRadius={'6px'} w={cardSize[layout]} bg="#FFFFFF0A" maxWidth={{ base: '200px', lg: '304px' }}>
            <Link to={`/${staking ? 'staking' : 'collection'}/${data?.network}/${data?.contractAddress}`}>
                <Image crossOrigin="anonymous" borderTopRadius={'6px'} objectFit={'cover'} w={'100%'} h={{ base: '204px', lg: '304px' }} src={updatedImageSrc} />
                <Box p={{ base: '12px', lg: '24px' }}>
                    <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontSize={{ base: '16px', lg: '18px' }} fontWeight={700}>{data?.name}</Text>
                    <Flex justify={'space-between'} w={'100%'} mt={{ base: '12px', lg: '16px' }}>
                        <Flex flexWrap={'wrap'} w={'49%'} >
                            <Box w={'100%'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontSize={'14px'} fontWeight={400} color={'#808489'}>{intl.formatMessage({ id: 'HOME.TRENDING.FLOOR' })}</Box>
                            <Box w={'100%'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} mt={{ base: '4px', lg: '8px' }} fontSize={{ base: '14px', lg: '16px' }} fontWeight={700}>{formatNumber(data?.floorPriceValue, 0.01)} {data?.floorPriceCurrency}</Box>
                        </Flex>
                        <Flex flexWrap={'wrap'} w={'49%'}>
                            <Box w={'100%'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontSize={'14px'} fontWeight={400} color={'#808489'}>{intl.formatMessage({ id: 'HOME.TRENDING.VOLUME' })}</Box>
                            <Box w={'100%'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} mt={{ base: '4px', lg: '8px' }} fontSize={{ base: '14px', lg: '16px' }} fontWeight={700}>{formatNumber(data?.volumeValue, 0.01)} {data?.volumeCurrency}</Box>
                        </Flex>
                    </Flex>
                </Box>
            </Link>
        </Box>
    )
}
