import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { AUTH_SERVICE } from '../../constants'
import { saveAuthorization } from '../../setup/axios/auth'
import Api from '../../setup/axios/SetupAxios'
import { objToQueryString } from '../../helpers/crud-helper/helpers'
import { UserData } from '../../models/UserModel'

const getNonce = (data: any) => {
    const payload = { walletAddress: data.walletAddress }
    return Api.AuthService.GET(AUTH_SERVICE.GET_NONCE + '?' + objToQueryString(payload))
}

export const signIn = (data: any) => Api.AuthService.POST(AUTH_SERVICE.SING_IN, data)

type DATA = {
    walletAddress: string
    name: string
    signMessage?: any
}


export const fetchSignIn = createAsyncThunk('sign/getUser', async (data: DATA) => {
    try {

        let sign
        let res
        const noncAsync = await getNonce(data)
        if (noncAsync.data) {
            const message = `Please sign to authorize user with nonce: ${noncAsync?.data?.nonce}`
            switch (data.name) {
                case 'MetaMask':
                    try {
                        sign = await window.ethereum?.request({
                            method: 'personal_sign',
                            params: [message, data.walletAddress],
                        })

                        if (sign) {
                            res = await signIn({ signature: sign, walletAddress: data.walletAddress })
                            saveAuthorization(res.data.data)
                        }
                    } catch (error) {
                        console?.log('error')
                        throw error
                    }
                    break
                default:
                    break;
            }
        } else {
            res = await signIn({ walletAddress: data.walletAddress })
            saveAuthorization(res.data.data)
        }
        return res.data
    } catch (error) {
        throw error
    }
})

type InitialState = {
    loading: boolean
    data: UserData | null,
    error: any
}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null
}

const singInSlice = createSlice({
    name: 'sign',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchSignIn.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchSignIn.fulfilled,
            (state, action: PayloadAction<InitialState>) => {
                state.loading = false
                state.data = action.payload?.data
                state.error = action.payload?.error
            }
        )
        builder.addCase(fetchSignIn.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.error = action?.error?.message || 'Something went wrong'
        })
    }
});

export default singInSlice.reducer