import { Box, Image, Text, CircularProgress, Button } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { NftType, StakingHistory } from '../../models/NftType'
import { User } from '../../models/UserModel'
import { STAKING_STATUS, URL_COLLECTION_DEFAULT, USDT_CURRENCY } from '../../constants'
import moment from 'moment'
import { formatNumber } from '../../helpers/crud-helper/helpers'

interface Props {
    nftData: NftType
    userData?: User | null
    loadingStaking: boolean
    loadingUnStaking: boolean
    onStaking: any
    unStaking: any
    stakingId: string | null
    disabledStaking: boolean
    disabledUnStaking: boolean
    timeUnstaking?: number
}

export const isTimeUnstaking = (unstakedAt: string, timeUnstaking: number) => {
    if (!unstakedAt) return true;
    const unstakedMoment = moment(unstakedAt);
    const now = moment();
    if (now.diff(unstakedMoment, 'seconds') < timeUnstaking) {
        return true
    } else {
        return false
    }
}

export const CardStaking: FC<Props> = ({ disabledUnStaking, loadingUnStaking, nftData, userData, loadingStaking, onStaking, stakingId, disabledStaking, unStaking, timeUnstaking }) => {

    const intl = useIntl()
    const updatedImageSrc = nftData?.image?.includes('ipfs.io') ? nftData?.image?.replace('ipfs.io', 'cloudflare-ipfs.com') : nftData?.image ?? URL_COLLECTION_DEFAULT

    const getHighestStaking = (stakingHistory: StakingHistory[]): StakingHistory | undefined => {
        let highestStaking: StakingHistory | undefined = undefined;
        if (stakingHistory.length === 0) return highestStaking;

        highestStaking = stakingHistory[0]

        for (const item of stakingHistory) {
            if (!highestStaking.stakingId || item.stakingId > highestStaking.stakingId) {
                highestStaking = item;
            }
        }

        return highestStaking;
    };


    const highestStaking: StakingHistory | undefined = nftData?.stakingHistory ? getHighestStaking(nftData.stakingHistory) : undefined;

    const [isDisabledWhenUnstaking, setIsDisabledWhenUnstaking] = useState(true)

    useEffect(() => {
        if (!timeUnstaking || !highestStaking?.unstakedAt) return
        setIsDisabledWhenUnstaking(isTimeUnstaking(highestStaking?.unstakedAt, timeUnstaking))
    }, [timeUnstaking, highestStaking])



    return (
        <Box position={'relative'} key={nftData?.id} cursor={'pointer'} borderRadius={'6px'} w={{ base: 'calc(50% - 6px)', lg: 'calc(25% - 24px)' }} bg="#FFFFFF0A">
            <Link to={`/staking/${nftData?.collection?.network}/${nftData?.contractAddress}/${nftData?.tokenId}`}>
                <Image borderTopRadius={'6px'} objectFit={'cover'} w={'100%'} h={{ base: '173px', lg: '304px' }} src={updatedImageSrc} />
                <Box p={{ base: '16px', lg: '24px' }} mb={'48px'}>
                    <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} fontSize={{ base: '16px', lg: '18px' }} fontWeight={700}>{nftData?.name ? nftData?.name : `#${nftData?.tokenId}`}</Text>
                    <Box
                        mb={{ base: '20px', lg: '24px' }}
                        mt={{ base: '12px', lg: '16px' }}
                        marginTop={{ base: '12px', lg: '16px' }}
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexDirection={{ base: 'column', lg: 'row' }}
                    >
                        <Text fontSize={{ base: '14px' }} fontWeight={400} color={'#808489'} noOfLines={1}>
                            {intl.formatMessage({ id: 'NFT.STAKING.PRICE' })}
                        </Text>
                        <Text fontSize={{ base: '14px', lg: '16px' }} fontWeight={700} color={'#FFFFFF'}>
                            {formatNumber(nftData?.collection?.price, 0.001) || 0} {nftData?.collection?.currency || USDT_CURRENCY}
                        </Text>
                    </Box>
                </Box>
            </Link>
            <Box position={'absolute'} bottom={0} w={'100%'} p={{ base: '16px', lg: '24px' }} >
                {(highestStaking?.status === STAKING_STATUS?.STAKED || highestStaking?.status === STAKING_STATUS?.UNSTAKING) && nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string) &&
                    <Button
                        onClick={nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string) ? () => unStaking(nftData, highestStaking) : () => { }}
                        w={'100%'}
                        type='submit'
                        as={'button'}
                        color='#FFFFFF'
                        cursor={'pointer'}
                        rounded="6px"
                        fontSize={{ base: '14px', lg: '16px' }}
                        fontWeight='600'
                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                        bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                        p={'2px'}
                        height={{ base: '32px', lg: '40px' }}
                        _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                        _active={{
                            bg: '#dddfe2',
                            transform: 'scale(0.98)',
                            borderColor: '#bec3c9',
                        }}
                        isDisabled={(isDisabledWhenUnstaking && highestStaking?.status === STAKING_STATUS?.UNSTAKING) || loadingUnStaking || disabledUnStaking || disabledStaking || !nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string)}
                    >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg={false ? "#131619" : ''} rounded="4px">
                            <Box w={'100%'} color={false ? '#FFFFFF' : "#131619"} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                {intl.formatMessage({ id: 'NFT.UNSTAKING' })}
                                {loadingUnStaking && <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='green.700' />}
                            </Box>
                        </Box>
                    </Button>}
                {(!highestStaking || highestStaking?.status === STAKING_STATUS?.UNSTAKED || ((highestStaking?.status === STAKING_STATUS?.UNSTAKING || highestStaking?.status === STAKING_STATUS?.STAKED) && !nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string))) &&
                    <Button
                        w={'100%'}
                        type='button'
                        as={'button'}
                        color='#FFFFFF'
                        cursor={'pointer'}
                        rounded="6px"
                        fontSize={{ base: '14px', lg: '16px' }}
                        fontWeight='600'
                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                        bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                        p={'2px'}
                        height={{ base: '32px', lg: '40px' }}
                        _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                        _active={{
                            bg: '#dddfe2',
                            transform: 'scale(0.98)',
                            borderColor: '#bec3c9',
                        }}
                        onClick={nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string) ? () => onStaking(nftData) : () => { }}
                        isDisabled={Boolean(nftData?.setting) || disabledUnStaking || disabledStaking || !nftData?.owner?.some(owner => owner?.toLowerCase() === userData?.walletAddress?.toLowerCase() as string) || loadingStaking || !nftData?.collection?.price}
                    >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg={'#131619'} rounded="4px">
                            <Box w={'100%'} color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                {intl.formatMessage({ id: 'NFT.STAKING' })}
                                {loadingStaking && <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='green.700' />}
                            </Box>
                        </Box>
                    </Button>
                }
            </Box>
        </Box>
    )
}
