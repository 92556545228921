import { Box, Flex, Text, Image } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ButtonCustom } from '../../chakra-ui/Button/ButtonCustom'
import { useIntl } from 'react-intl'
import { Autoplay, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getCollectionPublicApi } from '../../setup/api/apiCollectionTrending'
import { CollectionType } from '../../models/CollectionType'
import { SUPPORT_NETWORK, URL_COLLECTION_DEFAULT } from '../../constants'

export const CollectionTrending = () => {
    const intl = useIntl()
    const [collections, setCollections] = useState<CollectionType[]>()
    useEffect(() => {
        ;(async () => {
            const resp = await getCollectionPublicApi({ take: 5, network: SUPPORT_NETWORK, isSlider: true })
            if (resp?.data && Array.isArray(resp.data) && resp.data.length > 0) {
                setCollections(resp.data || [])
            } else {
                console.error('API response is not valid.')
            }
        })()
    }, [])

    return (
        <>
            <Swiper
                id="carousel"
                modules={[Navigation, Autoplay, Mousewheel]}
                pagination={false}
                loop={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                mousewheel={{ invert: false, forceToAxis: true }}
                navigation={true}
                initialSlide={1}
            >
                {collections?.map((item, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <Box w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Flex
                                    w={'100%'}
                                    borderRadius={'6px'}
                                    px={{ base: '24px', lg: 0 }}
                                    py={{ base: '40px', lg: 0 }}
                                    flexWrap={{ base: 'wrap' }}
                                    bgImage={item?.banner ? item?.banner : '/images/example/Rectangle.png'}
                                    bgSize={'cover'}
                                    h={{ base: 'auto', lg: '590px' }}
                                >
                                    <Box
                                        px={{ base: '0', lg: '80px' }}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        w={{ base: '100%', lg: '50%' }}
                                    >
                                        <Image
                                            objectFit="cover"
                                            src={
                                                item?.logo?.includes('ipfs.io')
                                                    ? item?.logo?.replace('ipfs.io', 'cloudflare-ipfs.com')
                                                    : item?.logo ?? URL_COLLECTION_DEFAULT
                                            }
                                            alt=""
                                        />
                                    </Box>
                                    <Box
                                        overflow={'hidden'}
                                        textOverflow={'ellipsis'}
                                        whiteSpace={'nowrap'}
                                        mt={{ base: '24px', lg: 0 }}
                                        pr={{ base: '0', lg: '80px' }}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        w={{ base: '100%', lg: '50%' }}
                                    >
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={{ base: 'center', lg: 'start' }}
                                            flexWrap={'wrap'}
                                            w={'100%'}
                                        >
                                            <Text
                                                overflow={'hidden'}
                                                textOverflow={'ellipsis'}
                                                whiteSpace={'nowrap'}
                                                align={{ base: 'center', lg: 'start' }}
                                                w={'100%'}
                                                fontSize={{ base: '36px', lg: '48px' }}
                                                fontWeight={'700'}
                                                as="p"
                                            >
                                                {item?.name}
                                            </Text>
                                            <Text
                                                whiteSpace={'normal'}
                                                align={{ base: 'center', lg: 'start' }}
                                                my={{ lg: '40px', base: '32px' }}
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                            >
                                                {item?.description}
                                            </Text>
                                            <ButtonCustom
                                                href={`/collection/${item?.network}/${item?.contractAddress}`}
                                                textButton={intl.formatMessage({ id: 'BUTTON.VIEW.COLLECTION' })}
                                                fontSizeButton={{ lg: '18px', base: '18px' }}
                                                heightButton={{ base: '48px', lg: '48px' }}
                                            />
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </SwiperSlide>
                    )
                })}
                <SwiperSlide>
                    <a target="_blank" style={{ height: '100%', width: '100%' }} href="https://pomocard.com/">
                        <Box w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Flex
                                w={'100%'}
                                borderRadius={'6px'}
                                px={{ base: '24px', lg: 0 }}
                                py={{ base: '40px', lg: 0 }}
                                flexWrap={{ base: 'wrap' }}
                                h={{ base: '100%', lg: '590px' }}
                            >
                                <picture>
                                    <source media="(max-width: 991px)" srcSet="/images/example/POM_LOGO_SP.png" type="image/png" />
                                    <source media="(min-width: 992px)" srcSet="/images/example/POM_LOGO_PC.png" type="image/png" />
                                    <img src="/images/example/POM_LOGO_PC.png" style={{ width: '100%', height: '100%' }} />
                                </picture>
                            </Flex>
                        </Box>
                    </a>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
