import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: true,

};

const breakpoints = {
  base: "0px",
  sm: "480px",
  md: "768px",
  lg: "992px",
  xl: "1280px",
  xxl: "1536px",
};

export const chakraCustomTheme = extendTheme({
  components: {
    Modal: {
      baseStyle: (props:any) => ({
        dialog: {
        }
      })
    }
  },
  config,
  breakpoints,
  styles: {
    global: () => ({
      body: {
        bg: "#131619",
      },
    }),
  },
});

export function deleteColorModeInLocalStorage() {
  window.localStorage.removeItem("chakra-ui-color-mode");
  console.log('deleted "chakra-ui-color-mode" from local storage');
  console.log("You can now refresh to see how a first visit looks like.");
}

setTimeout(deleteColorModeInLocalStorage, 3000);
