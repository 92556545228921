import React, { FC } from 'react'
import { NftDetailType } from '../../models/NftType'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { useIntl } from 'react-intl'
import { CardMoreNft } from './CardMoreNft'
import { Box } from '@chakra-ui/react'

type Props = {
    moreNftForCollection?: NftDetailType[]
}

const commonBiggerScreen = {
    centeredSlides: false,
    centeredSlidesBounds: false,
    spaceBetween: 16,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
}


export const MoreNft: FC<Props> = ({ moreNftForCollection }) => {
    const intl = useIntl()
    return (
        <>

            <Box display={{ base: 'none', lg: 'block' }} mt={{ base: 0, lg: '64px' }}>
                <Box fontSize={'24px'} fontWeight={700} mb={{ base: 0, lg: '40px' }}>{intl.formatMessage({ id: 'NFT.MORE.COLLECTION' })}</Box>
                <Swiper
                    modules={[Navigation, Mousewheel, Autoplay]}
                    mousewheel={{ invert: false, forceToAxis: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto',
                            slidesPerGroup: 1,
                            centeredSlides: true,
                            centeredSlidesBounds: true,
                            spaceBetween: 8
                        },
                        600: { slidesPerView: 2, slidesPerGroup: 2, ...commonBiggerScreen },
                        768: { slidesPerView: 3, slidesPerGroup: 3, ...commonBiggerScreen },
                        1024: { slidesPerView: 4, slidesPerGroup: 4, ...commonBiggerScreen },
                    }}
                    navigation
                    id="carousel"
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                >
                    {moreNftForCollection && moreNftForCollection?.map((nftData, index) => (
                        <SwiperSlide key={index}>
                            <CardMoreNft
                                key={index}
                                nftData={nftData}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </>
    )
}
