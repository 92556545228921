import { BigNumber, ethers } from 'ethers'
import { Utils } from './utils'
import NftStakingAbi from './abi/NFTStaking.abi.json'
import { saveLogDataApi } from '../../setup/api/apiLog'

export type StakingRequest = {
    nftAddress: string
    tokenId: (string | number | bigint | BigNumber) & (string | number | bigint | BigNumber | undefined)
    price: string | number | bigint | BigNumber
    expiryDate: number
    signature: string
}

export class StakingUtils {
    // READ

    public static async claimWaitingTime(network: string, stakingContractAddress: string): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'claimWaitingTime', [])
    }

    public static async unstakeWaitingTime(network: string, stakingContractAddress: string): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'unstakeWaitingTime', [])
    }

    public static async getTotalPrice(network: string, stakingContractAddress: string, user: string): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'getTotalPrice', [user])
    }

    public static async getAPY(network: string, stakingContractAddress: string, totalPrice: BigNumber): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'getAPY', [totalPrice])
    }

    public static async getClaimableReward(network: string, stakingContractAddress: string, stakingId: BigNumber): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'getClaimableReward', [stakingId])
    }

    public static async getClaimableRewards(network: string, stakingContractAddress: string, user: string): Promise<BigNumber | null> {
        return await this.call(network, stakingContractAddress, 'getClaimableRewards', [user])
    }

    public static async getStakingInfo(network: string, stakingContractAddress: string, stakingId: BigNumber): Promise<any | null> {
        return await this.call(network, stakingContractAddress, 'getStakingInfo', [stakingId])
    }

    public static async getUserStakingCount(network: string, stakingContractAddress: string, user: string): Promise<any | null> {
        return await this.call(network, stakingContractAddress, 'getUserStakingCount', [user])
    }

    public static async getUserStakingInfo(network: string, stakingContractAddress: string, user: string, index: number): Promise<any | null> {
        return await this.call(network, stakingContractAddress, 'getUserStakingInfo', [user, index])
    }

    // WRITE

    public static async stake(signer: ethers.Signer, stakingContractAddress: string, stakingRequest: StakingRequest): Promise<any | null> {
        const tx = await this.send(signer, stakingContractAddress, 'stake', [stakingRequest])
        return {
            ...tx,
            id: ethers.BigNumber.from(tx?.receipt?.events?.[tx?.receipt?.events?.length - 2]?.topics?.[1]),
        }
    }

    public static async unstake(signer: ethers.Signer, stakingContractAddress: string, stakingId: number): Promise<any | null> {
        return await this.send(signer, stakingContractAddress, 'unstake', [stakingId])
    }

    public static async forceUnstake(signer: ethers.Signer, stakingContractAddress: string, stakingId: number): Promise<any | null> {
        return await this.send(signer, stakingContractAddress, 'forceUnstake', [stakingId])
    }

    public static async claimReward(signer: ethers.Signer, stakingContractAddress: string, stakingId: BigNumber): Promise<any | null> {
        return await this.send(signer, stakingContractAddress, 'claimReward', [stakingId])
    }

    public static async claimRewards(signer: ethers.Signer, stakingContractAddress: string): Promise<any | null> {
        return await this.send(signer, stakingContractAddress, 'claimRewards', [])
    }

    public static async call(network: string, contractAddress: string, functionName: string, args: any[]): Promise<any | null> {
        const sdk = Utils.getSdk(network)

        const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(NftStakingAbi))

        return await contract.call(functionName, args)
    }

    public static async send(
        signer: ethers.Signer,
        contractAddress: string,
        functionName: string,
        args: any[],
        nativeTokenValue?: BigNumber
    ): Promise<any | null> {
        try {
            const sdk = await Utils.getSdkFromSigner(signer)

            const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(NftStakingAbi))

            return await contract.call(functionName, args, { value: nativeTokenValue || 0 })
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress,
                functionName,
                args,
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }
}
