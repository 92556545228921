import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { MainHomePage } from '../../components/MainHomePage'
import { BasicLayout } from './BasicLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { CollectionPage } from '../pages/CollectionPage'
import { NftStakingPage } from '../pages/NftStakingPage'
import { CollectionDetailPage } from '../pages/CollectionDetailPage'
import { NftDetailPage } from '../pages/NftDetailPage'
import { NftStakingDetailPage } from '../pages/NftStakingDetailPage'

export const ConnectRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    element={
                        <>
                            <BasicLayout />
                        </>
                    }
                >
                    <Route path='/' element={<MainHomePage />} />
                    <Route path='/collection' element={<CollectionPage />} />
                    <Route path='/collection/all' element={<CollectionPage />} />
                    <Route path='/collection/staking' element={<CollectionPage />} />
                    <Route path='/collection/art' element={<CollectionPage />} />
                    <Route path='/collection/gamming' element={<CollectionPage />} />
                    <Route path='/staking/:network/:contractId' element={<NftStakingPage />} />
                    <Route path='/staking/:network/:contractId/:tokenId' element={<NftStakingDetailPage />} />
                    <Route path='/collection/:network/:contractId' element={<CollectionDetailPage />} />
                    <Route path='/assets/:network/:contractId/:tokenId' element={<NftDetailPage />} />
                    {/* <Route path='/my-account' element={<HeaderMenu />} /> */}

                </Route>
                <Route path='/*' element={<PrivateRoutes />} />
            </Routes>

        </ >
    )
}
