import axios, { AxiosRequestConfig } from 'axios'
import { ACCESS_TOKEN } from '../../constants'
import { refreshToken } from './api'
import { logout } from './auth'

const AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE
const COLLECTION_SERVICE = process.env.REACT_APP_AUTH_SERVICE
const NFT_SERVICE = process.env.REACT_APP_AUTH_SERVICE
const PRICE_SERVICE = process.env.REACT_APP_PRICE_SERVICE
const PUBLIC_SERVICE = process.env.REACT_APP_PUBLIC_SERVICE


export const ERROR_HTTP = {
    UNAUTHORIZED: 401,
    PERMISSION_DENIED: 403,
    SUCCEEDED: 200,
    CREATED: 201,
    UPDATED: 202,
    MAXOTP: 33007,
    IVALID_OTP: 33013,
    CONTACT_ALREADY_VERIFIED: 32019,
    OTP_MAX_ALLOW: 33014,
    SYSTEM: 500,
}

export class API {
    instance: any
    baseUrl: any
    role = ''
    isClientCredential?: boolean
    isAuthService: boolean
    static account_link = AUTH_SERVICE
    constructor(link = '', isAuthService = false) {
        this.isAuthService = isAuthService;
        this.setBaseUrl(link)
        this.setupInterceptors()
    }

    setBaseUrl(link: string) {
        this.baseUrl = link
    }

    async setupInterceptors() {
        this.instance = await axios.create({
            baseURL: this.baseUrl,
            timeout: 300000,
            headers: { 'Content-Type': 'application/json' },
        })
        this.interceptors()
    }

    interceptors() {
        const self = this
        let failedQueue: any[] = []

        const processQueue = (error: any, token = null) => {
            failedQueue.forEach((from) => {
                if (error) {
                    from.reject(error)
                } else {
                    from.resolve(token)
                }
            })

            failedQueue = []
        }
        const interceptor = self.instance.interceptors.response.use(
            (response: any) => {
                return response
            },
            async (error: any) => {
                const {
                    config,
                    response: { status, data },
                } = error
                const originalRequest = config
                if (status !== ERROR_HTTP.UNAUTHORIZED) {
                    return Promise.reject(error)
                }
                axios.interceptors.response.eject(interceptor)
                if (status === ERROR_HTTP.UNAUTHORIZED && data?.code === '40101' && !originalRequest._retry) {
                    if (self.isClientCredential) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject })
                        })
                            .then((token) => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token
                                return self.instance(originalRequest)
                            })
                            .catch((err) => {
                                return Promise.reject(err)
                            })
                    }
                    originalRequest._retry = true
                    self.isClientCredential = true

                    try {
                        const accessToken = await refreshToken()
                        if (accessToken) {
                            localStorage.setItem(ACCESS_TOKEN, accessToken)
                            originalRequest.headers.Authorization = `Bearer ${accessToken}`
                            processQueue(null, accessToken)
                        } else {
                            logout()
                        }

                        return self.instance(originalRequest)
                    } catch (_error: any) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data)
                        }
                        processQueue(_error, null)
                        return Promise.reject(_error)
                    } finally {
                        self.isClientCredential = false
                    }
                }

                if (status === ERROR_HTTP.UNAUTHORIZED && data?.code === '40102') {
                    logout()
                    window.location.reload()
                }

                if (error.response.status === ERROR_HTTP.PERMISSION_DENIED && error.response.data) {
                    return Promise.reject(error.response.data)
                }

                return Promise.reject(error)
            }
        )

        self.instance.interceptors.request.use(
            async (config: any) => {
                if (self.isAuthService) {
                    const accessToken = localStorage.getItem(ACCESS_TOKEN)
                    if (accessToken) {
                        config.headers.Authorization = `Bearer ${accessToken}`
                    }
                }
                return config
            },
            (error: any) => {
                return Promise.reject(error)
            }
        )
    }

    POST(endpoint: string, data?: any, other?: AxiosRequestConfig<any> | undefined) {
        return this.instance
            .post(endpoint, data, other)
            .then((res: { data: any }) => {
                return res ?? { code: 500, message: 'Error api service' }
            })
            .catch((err: { response: { data: any } }) => {
                return err?.response?.data
            })
    }

    GET(endpoint: string, other?: AxiosRequestConfig<any> | undefined) {
        return this.instance
            .get(endpoint, other)
            .then((res: { data: any }) => {
                return res.data ?? { code: 500, message: 'Error api service' }
            })
            .catch((err: { response: { data: any } }) => {
                return err?.response?.data
            })
    }

    DELETE(endpoint: string, data?: any, other?: any) {
        return this.instance
            .delete(endpoint, { data: data }, other)
            .then((res: { data: any }) => {
                return res ?? { code: 500, message: 'Error api service' }
            })
            .catch((err: { response: { data: any } }) => {
                return err?.response?.data
            })
    }

    PUT(endpoint: string, data?: any, other?: AxiosRequestConfig<any> | undefined) {
        return this.instance
            .put(endpoint, data, other)
            .then((res: { data: any }) => {
                return res ?? { code: 500, message: 'Error api service' }
            })
            .catch((err: { response: { data: any } }) => {
                return err?.response?.data
            })
    }

    UPLOAD(endpoint: string, formData: any, onUploadProgress = null) {
        return this.instance
            .post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            })
            .then((res: { data: any }) => {
                return res ?? { result: false, message: 'Error api service' }
            })
            .catch((err: { response: { data: any } }) => {
                return err?.response?.data
            })
    }
}

const AuthService = new API(AUTH_SERVICE, true)
const CollectionService = new API(COLLECTION_SERVICE, true)
const NFTService = new API(NFT_SERVICE, true)
const PriceService = new API(PRICE_SERVICE, true)
const PublicService = new API(PUBLIC_SERVICE)


export default {
    AuthService,
    CollectionService,
    NFTService,
    PriceService,
    PublicService
}
