import { Box, Text } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
type Props = {
    heightButton?: {
        lg: string,
        base: string
    }
    fontSizeButton?: {
        lg: string,
        base: string
    }
    textButton?: string,
    href?: string
}

export const ButtonCustom: FC<Props> = ({ heightButton, fontSizeButton, textButton, href }) => {

    return (
        <>
            <Box display={'flex'} justifyContent={{ base: 'center', lg: 'normal' }} w={'100%'}>
                <Link to={href!}>
                    <Box
                        as={'button'}
                        color='#FFFFFF'
                        cursor={'pointer'}
                        rounded="6px"
                        fontSize={{ base: fontSizeButton?.base, lg: fontSizeButton?.lg }}
                        fontWeight='600'
                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                        bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                        p={'2px'}
                        height={{ base: heightButton?.base, lg: heightButton?.lg }}
                        _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}
                        _focus={{
                            boxShadow:
                                '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                        }}
                        _active={{
                            bg: '#dddfe2',
                            transform: 'scale(0.98)',
                            borderColor: '#bec3c9',
                        }}
                    >
                        <Box h={'100%'} bg="#131619" rounded="4px">
                            <Box style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>{textButton}</Box>
                        </Box>
                    </Box>
                </Link>
            </Box>
        </>
    )
}
