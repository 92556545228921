import { BigNumber, ethers } from 'ethers'
import { Utils } from './utils'
import { NFT } from '@thirdweb-dev/sdk'
import Erc721Abi from './abi/ERC721.abi.json'
import { saveLogDataApi } from '../../setup/api/apiLog'

export class NFTUtils {
    // READ

    public static async getName(network: string, nftContractAddress: string): Promise<string | null> {
        return await this.call(network, nftContractAddress, 'name', [])
    }

    public static async symbol(network: string, nftContractAddress: string): Promise<string | null> {
        return await this.call(network, nftContractAddress, 'symbol', [])
    }

    public static async tokenURI(network: string, nftContractAddress: string, tokenId: number): Promise<string | null> {
        return await this.call(network, nftContractAddress, 'tokenURI', [tokenId])
    }

    public static async getNFT(network: string, nftContractAddress: string, tokenId: number): Promise<NFT | null> {
        const sdk = Utils.getSdk(network)

        const nftContract = await sdk.getContract(nftContractAddress)

        return await nftContract.erc721.get(tokenId)
    }

    public static async totalCount(network: string, nftContractAddress: string): Promise<BigNumber | null> {
        const sdk = Utils.getSdk(network)

        const nftContract = await sdk.getContract(nftContractAddress)

        return await nftContract.erc721.totalCount()
    }

    public static async balanceOf(network: string, nftContractAddress: string, owner: string): Promise<BigNumber | null> {
        return await this.call(network, nftContractAddress, 'balanceOf', [owner])
    }

    public static async ownerOf(network: string, nftContractAddress: string, tokenId: number): Promise<string | null> {
        return await this.call(network, nftContractAddress, 'ownerOf', [tokenId])
    }

    public static async getApproved(network: string, nftContractAddress: string, tokenId: number): Promise<string | null> {
        return await this.call(network, nftContractAddress, 'getApproved', [tokenId])
    }

    public static async isApprovedForAll(network: string, nftContractAddress: string, owner: string, operator: string): Promise<boolean | null> {
        return await this.call(network, nftContractAddress, 'isApprovedForAll', [owner, operator])
    }

    // WRITE

    public static async approve(signer: ethers.Signer, nftContractAddress: string, to: string, tokenId: number): Promise<string | null> {
        return await this.send(signer, nftContractAddress, 'approve', [to, tokenId])
    }

    public static async setApprovalForAll(signer: ethers.Signer, nftContractAddress: string, to: string, approved: boolean): Promise<string | null> {
        return await this.send(signer, nftContractAddress, 'setApprovalForAll', [to, approved])
    }

    public static async transferFrom(
        signer: ethers.Signer,
        nftContractAddress: string,
        from: string,
        to: string,
        tokenId: number
    ): Promise<string | null> {
        return await this.send(signer, nftContractAddress, 'transferFrom', [from, to, tokenId])
    }

    public static async safeTransferFrom(
        signer: ethers.Signer,
        nftContractAddress: string,
        from: string,
        to: string,
        tokenId: number
    ): Promise<string | null> {
        return await this.send(signer, nftContractAddress, 'safeTransferFrom', [from, to, tokenId])
    }

    public static async call(network: string, contractAddress: string, functionName: string, args: any[]): Promise<any | null> {
        const sdk = Utils.getSdk(network)

        const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(Erc721Abi))

        return await contract.call(functionName, args)
    }

    public static async send(
        signer: ethers.Signer,
        contractAddress: string,
        functionName: string,
        args: any[],
        nativeTokenValue?: BigNumber
    ): Promise<any | null> {
        try {
            const sdk = await Utils.getSdkFromSigner(signer)

            const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(Erc721Abi))

            return await contract.call(functionName, args, { value: nativeTokenValue || 0 })
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress,
                functionName,
                args,
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }
}
