import Api from './SetupAxios'
import { AUTH_SERVICE } from '../../constants/index'
import { saveAuthorization } from './auth'

export function refreshToken() {
    const _refreshToken = localStorage.getItem('refresh_token') || ''
    return Api.AuthService.POST(AUTH_SERVICE.REFRESH_TOKEN, {
        refreshToken: _refreshToken,
    }).then((response: any) => {
        saveAuthorization(response?.data)
        return response?.data?.access_token
    })
}