import React from 'react'
import { Outlet } from 'react-router-dom'
import { HeaderMenu } from '../../components/HeaderMenu/HeaderMenu'
import { FooterMenu } from '../../components/FooterMenu/FooterMenu'

export const BasicLayout = () => {
  return (
    <>
        <HeaderMenu />
        <Outlet />
        <FooterMenu />
      </>
  )
}