import { Flex } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { WalletContext } from '../../app/wallet/walletContext'
import { WalletBlance } from './WalletBlance'
import { useLocation } from 'react-router-dom'

export const WalletConnectButton = () => {
    const intl = useIntl()
    // const loginOptional = false;
    // const showConnectEmbed = useShowConnectEmbed(loginOptional);

    const location = useLocation();

    const { openModalLogin, setOpenModalLogin, isLogin } = useContext(WalletContext)

    const handleLogin = (path: string) => {
        localStorage.setItem('redirectPath', path);
        setOpenModalLogin(!openModalLogin)
    }
    return (

        <>
            {!isLogin ?
                <Flex
                    fontSize={'16px'}
                    fontWeight={700}
                    cursor={'pointer'}
                    align={'center'}
                    justify={'center'}
                    p={'24px'}
                    borderRadius={'6px'}
                    backgroundColor={'#FFFFFF0A'}
                    h='48px'
                    onClick={() => handleLogin(location?.pathname)}
                    _hover={{
                        bg: 'gray.400'
                    }}
                >
                    {intl.formatMessage({ id: 'MENU.LOGIN' })}
                </Flex>
                :
                // <Box>

                //     <ConnectWallet
                //         modalSize={"wide"}
                //         auth={{ loginOptional: false }}
                //         theme={darkTheme({
                //             colors: {
                //                 accentText: "",
                //                 accentButtonBg: "#FFFFFF0A",
                //                 modalBg: "#FFFFFF0A",
                //                 primaryText: "#ededef",
                //                 borderColor: "#262830",
                //                 separatorLine: "#262830",
                //                 danger: "#e54d2e",
                //                 success: "#5bb98c",
                //                 accentButtonText: "#FFFFFF0A",
                //                 primaryButtonBg: "#FFFFFF0A",
                //                 primaryButtonText: "#FFFFFF",
                //                 secondaryIconColor: ""
                //             },
                //         })}

                //         btnTitle={intl.formatMessage({ id: 'MENU.LOGIN' })}
                //         modalTitleIconUrl={""}
                //         showThirdwebBranding={false}
                //     />
                // </Box>
                <WalletBlance />

            }
        </>
    )
}