import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Text, Input, InputGroup, InputLeftElement, Hide, Icon, Spinner, useMediaQuery } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useIntl } from 'react-intl'
import { CardCollection } from '../../components/CardItem/CardCollection'
import { getAllCollectionForCategory, resetCollections } from '../../components/CollectionPage/collectionSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { debounce } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SUPPORT_NETWORK } from '../../constants'
import { fetchUserProfile } from '../../components/ProfilePage/userProfileSlice'
import { WalletContext } from '../wallet/walletContext'

export enum CardLayout {
    COZY = 'cozy',
    COMPACT = 'compact',
}

export const DEFAULT_TAKE = 10
export const DEFAULT_SKIP = 0

export const CollectionPage = () => {
    const [isLg] = useMediaQuery('(min-width: 992px)')
    const intl = useIntl();
    const location = useLocation()
    const dispatch = useAppDispatch()

    const { isLogin, tokenExpiredCb } = useContext(WalletContext)

    const { data: collectionData } = useAppSelector((state) => state.collectionData)

    const [search, setSearch] = useState<string>()

    const searchRef = useRef(search)

    const onSearch = async () => {
        const res = await dispatch(fetchUserProfile(tokenExpiredCb))
        switch (location?.pathname) {
            case '/collection/staking':
                dispatch(
                    getAllCollectionForCategory({
                        name: searchRef.current,
                        isStaking: true,
                        take: DEFAULT_TAKE,
                        skip: (collectionData?.options?.currentPage || 0) * DEFAULT_TAKE,
                        network: SUPPORT_NETWORK,
                        walletAddress: res?.payload?.walletAddress
                    })
                )
                break;
            case '/collection/all':
                dispatch(
                    getAllCollectionForCategory({
                        name: searchRef.current,
                        take: DEFAULT_TAKE,
                        skip: (collectionData?.options?.currentPage || 0) * DEFAULT_TAKE,
                        network: SUPPORT_NETWORK
                    })
                )
                break;
            case '/collection/art':
                dispatch(
                    getAllCollectionForCategory({
                        name: searchRef.current,
                        isStaking: true,
                        take: DEFAULT_TAKE,
                        skip: (collectionData?.options?.currentPage || 0) * DEFAULT_TAKE,
                        categorySlug: 'art',
                        network: SUPPORT_NETWORK
                    })
                )
                break;
            case '/collection/gaming':
                dispatch(
                    getAllCollectionForCategory({
                        name: searchRef.current,
                        isStaking: true,
                        take: DEFAULT_TAKE,
                        skip: (collectionData?.options?.currentPage || 0) * DEFAULT_TAKE,
                        categorySlug: 'gaming',
                        network: SUPPORT_NETWORK
                    })
                )
                break;
            default:
                break;
        }
    }

    const debouncedSearch = useRef(debounce(onSearch, 300)).current

    useEffect(() => {
        debouncedSearch()
        return () => {
            dispatch(resetCollections())
        }
    }, [location, search, debouncedSearch, isLogin])


    useEffect(() => {
        searchRef.current = search
    }, [search])

    const [selectedLayout, setSelectedLayout] = useState<CardLayout>(CardLayout.COZY)

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        localStorage.setItem('redirectPath', location?.pathname)
    }, [])

    useEffect(() => {
        setSearch('')
    }, [location?.pathname])


    return (
        <Container maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0}>
            <Box
                display={'flex'}
                flexDirection={{ base: 'column', lg: 'row' }}
                gap={'16px'}
                mb={{ lg: '40px', base: '32px' }}
            >
                <Box
                    w={{ lg: '100%' }}
                    display={{ base: 'flex' }}
                    flexDirection={{ base: 'column', lg: 'row' }}
                    gap={{ base: '12px' }}
                >
                    <Box display={'flex'} w={{ base: '100%', lg: 'calc(100%)' }} h={'40px'}>
                        <InputGroup w={{ base: 'calc(100% - 52px)', lg: 'calc(100% - 16px)' }} mr={{ base: '12px', lg: '16px' }}>
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon color="gray.300" />
                            </InputLeftElement>
                            <Input
                                type="search"
                                placeholder={intl.formatMessage({ id: 'PROFILE.SEARCH_BY_NAME' })}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </InputGroup>
                    </Box>
                </Box>

                <Hide breakpoint="(max-width: 960px)">
                    <Box
                        bg={'#1C1F22'}
                        h={'40px'}
                        w={'78px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        borderRadius={'6px'}
                        boxSizing="border-box"
                        padding={'2px'}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            w={'36px'}
                            h={'36px'}
                            borderRadius={'8px'}
                            bg={selectedLayout === CardLayout.COZY ? '#131619' : '#1C1F22'}
                            cursor={'pointer'}
                            onClick={() => setSelectedLayout(CardLayout.COZY)}
                        >
                            <Icon display={'block'} h={'24px'} w={'24px'} viewBox="0 0 24 24" fill="none" color="#515458">
                                <path
                                    d="M18.75 3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM18.75 11.25H12.75V5.25H18.75V11.25ZM11.25 5.25V11.25H5.25V5.25H11.25ZM5.25 12.75H11.25V18.75H5.25V12.75ZM18.75 18.75H12.75V12.75H18.75V18.75Z"
                                    fill="white"
                                />
                            </Icon>
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            w={'36px'}
                            h={'36px'}
                            borderRadius={'8px'}
                            bg={selectedLayout === CardLayout.COMPACT ? '#131619' : '#1C1F22'}
                            cursor={'pointer'}
                            onClick={() => setSelectedLayout(CardLayout.COMPACT)}
                        >
                            <Icon display={'block'} h={'24px'} w={'24px'} viewBox="0 0 24 24" fill="none" color="#515458">
                                <path
                                    d="M20.25 4.5H3.75C3.35218 4.5 2.97064 4.65804 2.68934 4.93934C2.40804 5.22064 2.25 5.60218 2.25 6V18C2.25 18.3978 2.40804 18.7794 2.68934 19.0607C2.97064 19.342 3.35218 19.5 3.75 19.5H20.25C20.6478 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V6C21.75 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6478 4.5 20.25 4.5ZM9.75 13.5V10.5H14.25V13.5H9.75ZM14.25 15V18H9.75V15H14.25ZM3.75 10.5H8.25V13.5H3.75V10.5ZM9.75 9V6H14.25V9H9.75ZM15.75 10.5H20.25V13.5H15.75V10.5ZM20.25 9H15.75V6H20.25V9ZM8.25 6V9H3.75V6H8.25ZM3.75 15H8.25V18H3.75V15ZM20.25 18H15.75V15H20.25V18Z"
                                    fill="#515458"
                                />
                            </Icon>
                        </Box>
                    </Box>
                </Hide>
            </Box>
            {collectionData && collectionData.data?.length > 0 ? (
                <InfiniteScroll
                    dataLength={collectionData.data.length}
                    next={onSearch}
                    hasMore={collectionData.data.length < (collectionData?.options?.totalItems as number)}
                    style={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: isLg ? '32px' : '12px',
                        justifyContent: isLg ? 'start' : 'space-between',
                    }}
                    loader={<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />}
                >
                    {collectionData.data?.map((item, index) => (
                        <CardCollection
                            key={index}
                            data={item}
                            layout={selectedLayout}
                            staking={Boolean(location?.pathname === '/collection/staking')}
                        />
                    ))}
                </InfiniteScroll>
            ) : (
                <>
                    <Box margin={'auto'}>
                        <Text>{intl.formatMessage({ id: 'COLLECTION.NFT.NO_NFT' })}</Text>
                    </Box>
                </>
            )}
        </Container>
    )
}
