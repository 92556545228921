import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { CollectionType } from '../../models/CollectionType'
import { getCollectionPublicApi } from '../../setup/api/apiCollectionTrending'
import { Link } from 'react-router-dom'
import { CardCollection } from './CardCollection'
import { SUPPORT_NETWORK } from '../../constants'

const commonBiggerScreen = {
    centeredSlides: false,
    centeredSlidesBounds: false,
    spaceBetween: 16,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
}

export const TrenddingInGamingTopPage = () => {
    const intl = useIntl()
    const [collectionTrenddingInGaming, setCollectionTrenddingInGaming] = useState<CollectionType[]>()
    useEffect(() => {
        (async () => {
            const resp = await getCollectionPublicApi({ take: 4, categorySlug: 'gaming', network: SUPPORT_NETWORK })
            setCollectionTrenddingInGaming(resp?.data)
        })()

    }, [])

    return (
        <>
            {collectionTrenddingInGaming && collectionTrenddingInGaming?.length > 0 &&
                <Box w={'100%'} mt={{ base: '40px', lg: '80px' }}>
                    <Flex w={'100%'} >
                        <Box>
                            <Text fontSize={{ lg: '32px', base: '24px' }} fontWeight={700}>{intl.formatMessage({ id: 'HOME.TRENDING.IN.GAMING' })}</Text>
                        </Box>
                        <Spacer />
                        <Box
                            cursor={'pointer'}
                            fontSize={{ lg: '18px', base: '16px' }}
                            fontWeight={600}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            borderRadius={'6px'}
                            h={{ base: '40px', lg: '48px' }}
                            px={{ base: '16px', lg: '24px' }}
                            backgroundColor={'#FFFFFF0A'}>
                            <Link to={`/collection/gaming`}>
                                {intl.formatMessage({ id: 'ACTION.VIEW.MORE' })}
                            </Link>
                        </Box>
                    </Flex>
                    <Box mt={{ base: '24px', lg: '40px' }}>
                        <Swiper
                            modules={[Navigation, Mousewheel]}
                            mousewheel={{ invert: false, forceToAxis: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto',
                                    slidesPerGroup: 1,
                                    centeredSlides: true,
                                    centeredSlidesBounds: true,
                                    spaceBetween: 8
                                },
                                600: { slidesPerView: 2, slidesPerGroup: 2, ...commonBiggerScreen },
                                768: { slidesPerView: 3, slidesPerGroup: 3, ...commonBiggerScreen },
                                1024: { slidesPerView: 4, slidesPerGroup: 4, ...commonBiggerScreen },
                            }}
                            navigation
                            className="collections-slide"
                        >
                            {collectionTrenddingInGaming?.map((collectionData, i) => (
                                <SwiperSlide key={i}>
                                    <CardCollection collectionData={collectionData} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>

                </Box>
            }
        </>
    )
}
