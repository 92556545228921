import { configureStore } from '@reduxjs/toolkit'
import singInSlice from '../app/auth/singInSlice'
import userProfileSlice from '../components/ProfilePage/userProfileSlice'
import userCollectionSlice from '../components/ProfilePage/userCollectionSlice'
import userNFTSlice from '../components/ProfilePage/userNFTSlice'
import nftsCollectionSlice from '../components/CollectionPage/nftsCollectionSlice'
import collectionSlice from '../components/CollectionPage/collectionSlice'
import nftsStakingByCollectionSlice from '../components/StakingNft/nftsStakingByCollectionSlice'

const store = configureStore({
    reducer: {
        userSign: singInSlice,
        userProfile: userProfileSlice,
        userCollection: userCollectionSlice,
        userNFT: userNFTSlice,
        nftCollection: nftsCollectionSlice,
        collectionData: collectionSlice,
        nftsStakingData: nftsStakingByCollectionSlice
    },
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
