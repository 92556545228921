import { AUTH_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"

export const checkReferralCodeApi = async ({referralCode}:{referralCode: string}): Promise<any> => {
    try {
        const params = {
            referralCode
        }
        const resp = await Api.AuthService.POST(AUTH_SERVICE.CHECK_REFERRAL_CODE, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

