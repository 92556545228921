import { Grid, Box, Image, Text, GridItem } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NftType } from '../../models/NftType'
import { formatNumber } from '../../helpers/crud-helper/helpers'
import { URL_COLLECTION_DEFAULT } from '../../constants'

type Props = {
    nftData: NftType
    layout: string
}

export enum CardLayout {
    COZY = 'cozy',
    COMPACT = 'compact',
}

export const CardNft: FC<Props> = ({ nftData, layout = CardLayout.COZY }) => {
    const intl = useIntl()

    const cardSize: any = {
        [CardLayout.COZY]: { base: 'calc(50% - 6px)', lg: 'calc(25% - 24px)' },
        [CardLayout.COMPACT]: { base: 'calc(50% - 6px)', lg: 'calc(20% - 25.6px)' },
    }
    const imgHeight: any = {
        [CardLayout.COZY]: { base: '173px', lg: '304px' },
        [CardLayout.COMPACT]: { base: '173px', lg: '230px' },
    }
    const updatedImageSrc = nftData?.image?.includes('ipfs.io')
        ? nftData?.image?.replace('ipfs.io', 'cloudflare-ipfs.com')
        : nftData?.image ?? URL_COLLECTION_DEFAULT

    return (
        <Box borderRadius={'6px'} w={cardSize[layout]} bg="#FFFFFF0A" maxWidth={{ base: '200px', lg: '304px' }}>
            <Link to={`/assets/${nftData?.collection?.network}/${nftData?.contractAddress}/${nftData?.tokenId}`}>
                <Image borderTopRadius={'6px'} objectFit={'cover'} w={'100%'} h={imgHeight[layout]} src={updatedImageSrc} />
                <Box p={{ base: '16px', lg: '24px' }}>
                    <Text
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        style={{ textWrap: 'nowrap' }}
                        fontSize={{ base: '16px', lg: '18px' }}
                        fontWeight={700}
                    >
                        {nftData?.name || `#${nftData?.tokenId}`}
                    </Text>
                    <Box
                        marginTop={{ base: '12px', lg: '16px' }}
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexDirection={{ base: 'column', lg: 'row' }}
                    >
                        <Text fontSize={'14px'} fontWeight={400} color={'#808489'}>
                            {intl.formatMessage({ id: 'NFT.SALE.PRICE' })}
                        </Text>
                        <Text fontSize={{ base: '14px', lg: '16px' }} fontWeight={700} color={'#FFFFFF'}>
                            {formatNumber(nftData?.setting?.price || nftData?.lastSaleValue, 0.001)}{' '}
                            {nftData?.setting?.currency || nftData?.lastSaleCurrency}
                        </Text>
                    </Box>
                </Box>
            </Link>
        </Box>
    )
}
