import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, Image, Box, Icon, Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { NftDetailType, PricePurchaseType } from '../../models/NftType'
import { CURRENCY, URL_COLLECTION_DEFAULT, USDT_CURRENCY } from '../../constants'
import { Link } from 'react-router-dom'

type Props = {
    openModalBuyNft: boolean
    onCloseModalBuyNft: () => void
    pricePurchase: PricePurchaseType
    nftInformation?: NftDetailType
}

export const BuyNftModal: FC<Props> = ({ openModalBuyNft, onCloseModalBuyNft, pricePurchase, nftInformation }) => {
    const intl = useIntl();
    return (
        <Modal isCentered size={'md'} isOpen={openModalBuyNft} onClose={onCloseModalBuyNft}>
            <ModalOverlay />
            <ModalContent alignItems={'center'} bg={'#131619'}>
                <Flex px={'32px'} py={'20px'} w={'100%'} align={'center'} justify={'end'}>
                    <ModalCloseButton />
                </Flex>
                <ModalBody px={'32px'} >
                    <Flex py={{ base: '24px', lg: '28px' }} w={{ lg: '100%%', base: '100%' }} justify={'center'} align={'center'} >
                        <Image
                            borderRadius={'6px'}
                            h={{ base: '160px', lg: '160px' }}
                            w={{ base: '160px', lg: '160px' }}
                            crossOrigin="anonymous"
                            objectFit={'contain'}
                            src={nftInformation?.image || URL_COLLECTION_DEFAULT}
                        />
                    </Flex>
                    <Flex mb={{ base: '24px', lg: '32px' }} flexWrap={'wrap'} justify={{ lg: 'center', base: 'center' }} align={'center'}>
                        <Box fontWeight={700} fontSize={{ lg: '28px', base: '24px' }} mb={{ base: '32px', lg: '40px' }}>{intl.formatMessage({ id: 'NFT.BUY.VIEW.PURCHASE' })}</Box>
                        <Flex w={'100%'} justify={'space-between'}>
                            <Box>
                                <Text mb={'8px'} fontWeight={400} fontSize={'16px'} color={'#808489'}>{intl.formatMessage({ id: 'NFT.BUY.SUB.TOTAL' })}</Text>
                                <Text fontWeight={400} fontSize={'16px'} color={'#808489'}>{intl.formatMessage({ id: 'NFT.BUY.GAS.FEES' })}</Text>
                            </Box>
                            <Box>
                                <Text mb={'8px'} fontWeight={500}>{pricePurchase?.subTotal} {USDT_CURRENCY}</Text>
                                <Text fontWeight={500}>{pricePurchase?.gasFee} {USDT_CURRENCY}</Text>
                            </Box>
                        </Flex>
                        <Box w={'100%'} height={'1px'} backgroundColor={'#27282A'} my={{ lg: '16px', base: '16px' }}></Box>
                        <Flex w={'100%'} justify={'space-between'}>
                            <Box fontWeight={700} fontSize={'16px'}>{intl.formatMessage({ id: 'NFT.BUY.TOTAL.PRICE' })}</Box>
                            <Box fontWeight={700} fontSize={'16px'}>{pricePurchase?.totalPrice} {USDT_CURRENCY}</Box>
                        </Flex>
                    </Flex>
                    <Box mb={{ base: '24px', lg: '32px' }}>
                        <Button
                            w={'100%'}
                            as={'button'}
                            color='#FFFFFF'
                            cursor={'pointer'}
                            rounded="6px"
                            fontSize={{ base: '18px', lg: '18px' }}
                            fontWeight='600'
                            transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                            bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                            p={'2px'}
                            height={{ base: '48px', lg: '48px' }}
                            _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                            _active={{
                                bg: '#dddfe2',
                                transform: 'scale(0.98)',
                                borderColor: '#bec3c9',
                            }}
                            onClick={onCloseModalBuyNft}
                        >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg="#131619" rounded="4px">
                                <Box w={'100%'} color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                    {intl.formatMessage({ id: 'NFT.BUY.BUTTON.VIEW.PURCHASE' })}
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                    {nftInformation?.collection?.price &&
                        <Link to={`/staking/${nftInformation?.collection?.network}/${nftInformation?.contractAddress}`}>
                            <Box mb={{ base: '24px', lg: '32px' }}>
                                <Button
                                    w={'100%'}
                                    as={'button'}
                                    color='#FFFFFF'
                                    cursor={'pointer'}
                                    rounded="6px"
                                    fontSize={{ base: '18px', lg: '18px' }}
                                    fontWeight='600'
                                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                    bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                                    p={'2px'}
                                    height={{ base: '48px', lg: '48px' }}
                                    _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                                    _active={{
                                        bg: '#dddfe2',
                                        transform: 'scale(0.98)',
                                        borderColor: '#bec3c9',
                                    }}
                                    onClick={onCloseModalBuyNft}
                                >
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg="#131619" rounded="4px">
                                        <Box w={'100%'} color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                            {intl.formatMessage({ id: 'NFT.BUY.BUTTON.STAKING.LIST' })}
                                        </Box>
                                    </Box>
                                </Button>
                            </Box>
                        </Link>
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
