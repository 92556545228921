import React, { useContext, createContext, useState, useEffect, ReactNode } from 'react';

interface I18nContextType {
    selectedLang: string;
    setLanguage: (lang: string) => void;
}

const I18N_CONFIG_KEY = 'i18nConfig';
const defaultLanguage = 'en';

const I18nContext = createContext<I18nContextType>({
    selectedLang: defaultLanguage,
    setLanguage: () => { }
});

export const useLang = () => useContext(I18nContext);

export const I18nContextProvider = ({ children }: { children: ReactNode }) => {
    const [selectedLang, setSelectedLang] = useState(getConfig().selectedLang);

    const setLanguage = (lang: string) => {
        localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
        setSelectedLang(lang);
    };

    useEffect(() => {
        const config = getConfig();
        setSelectedLang(config.selectedLang);
    }, []);

    return (
        <I18nContext.Provider value={{ selectedLang, setLanguage }}>
            {children}
        </I18nContext.Provider>
    );
}

function getConfig(): { selectedLang: string } {
    const ls = localStorage.getItem(I18N_CONFIG_KEY);
    if (ls) {
        return JSON.parse(ls);
    }
    return { selectedLang: getDefaultLanguage() };
}

function getDefaultLanguage(): string {
    try {
        const language = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
        return (language === 'en' || language === 'ja') ? language : 'en';
    } catch (e) {
        return 'en';
    }
}