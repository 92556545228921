import { AFFILIATE_SERVICE } from "../../constants";
import { objToQueryString } from "../../helpers/crud-helper/helpers";
import { AffiliateStatisticsType, AffiliateType, WithdrawReq } from "../../models/AffliateType";
import Api from "../axios/SetupAxios"

export const getAffiliateApi = async (network: string): Promise<AffiliateType[]> => {
    const params = {
        network
    }
    try {
        const resp = await Api.AuthService.GET(AFFILIATE_SERVICE.GET_AFFILIATE_LIST + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching affiliate data:", error);
        throw error
    }
}

export const getAffiliateStatisticsApi = async (network: string): Promise<AffiliateStatisticsType> => {
    const params = {
        network
    }
    try {
        const resp = await Api.AuthService.GET(AFFILIATE_SERVICE.GET_AFFILIATE_STATISTICS + '?' + objToQueryString(params));

        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching affiliate data:", error);
        throw error
    }
}

export const withdrawAffiliateApi = async (data?: WithdrawReq, tokenExpiredCb?: () => void): Promise<any> => {
    try {
        const params = {
            year: data?.year,
            month: data?.month,
            network: data?.network
        }
        const resp = await Api.AuthService.POST(AFFILIATE_SERVICE.WITHDRAWAL_AFFILIATE, params);
        if (resp?.errors?.[0]?.code === 4141) {
            tokenExpiredCb?.();
        }
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching affiliate data:", error);
        throw error
    }
}

export const getTimeRewardAffiliateApi = async ({ type, isAll }: { type: string, isAll: boolean }): Promise<{ data: { value: number, type: string }[] }> => {
    const params = {
        type,
        isAll
    }
    try {
        const resp = await Api.AuthService.GET(AFFILIATE_SERVICE.GET_CONFIG + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching affiliate data:", error);
        throw error
    }
}

