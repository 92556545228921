import { Flex, Icon } from '@chakra-ui/react'
import { useBalance } from '@thirdweb-dev/react';
import React from 'react'
import { USDT_CONTRACT_ADDRESS } from '../../constants';

export const WalletBlance = () => {
    const { data: balance } = useBalance(USDT_CONTRACT_ADDRESS)
    return (
        <>
            <Flex fontSize={'18px'} fontWeight={'700'} align={'center'} justify={'center'} p={'24px'} borderRadius={'6px'} backgroundColor={'#FFFFFF0A'} h='48px'>
                <Icon marginRight={'10px'} h={'24px'} w={'24px'} viewBox='0 0 24 24' color='white'>
                    <path d="M20.25 6.75H5.25C5.05109 6.75 4.86032 6.67098 4.71967 6.53033C4.57902 6.38968 4.5 6.19891 4.5 6C4.5 5.80109 4.57902 5.61032 4.71967 5.46967C4.86032 5.32902 5.05109 5.25 5.25 5.25H18C18.1989 5.25 18.3897 5.17098 18.5303 5.03033C18.671 4.88968 18.75 4.69891 18.75 4.5C18.75 4.30109 18.671 4.11032 18.5303 3.96967C18.3897 3.82902 18.1989 3.75 18 3.75H5.25C4.65326 3.75 4.08097 3.98705 3.65901 4.40901C3.23705 4.83097 3 5.40326 3 6V18C3 18.5967 3.23705 19.169 3.65901 19.591C4.08097 20.0129 4.65326 20.25 5.25 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V8.25C21.75 7.85218 21.592 7.47064 21.3107 7.18934C21.0294 6.90804 20.6478 6.75 20.25 6.75ZM16.875 14.25C16.6525 14.25 16.435 14.184 16.25 14.0604C16.065 13.9368 15.9208 13.7611 15.8356 13.5555C15.7505 13.35 15.7282 13.1238 15.7716 12.9055C15.815 12.6873 15.9222 12.4868 16.0795 12.3295C16.2368 12.1722 16.4373 12.065 16.6555 12.0216C16.8738 11.9782 17.1 12.0005 17.3055 12.0856C17.5111 12.1708 17.6868 12.315 17.8104 12.5C17.934 12.685 18 12.9025 18 13.125C18 13.4234 17.8815 13.7095 17.6705 13.9205C17.4595 14.1315 17.1734 14.25 16.875 14.25Z" fill="white" />
                </Icon>

                {(Math.trunc((balance?.displayValue as any || 0) * 100) / 100)} {balance?.symbol}
            </Flex>
        </>
    )
}
