import { BigNumber, ethers } from 'ethers'
import { Utils } from './utils'
import { saveLogDataApi } from '../../setup/api/apiLog'

export type Listing = {
    pricePerToken: string | number
    tokenId: (string | number | bigint | BigNumber) & (string | number | bigint | BigNumber | undefined)
    assetContractAddress: string
    quantity?: string | number | bigint | BigNumber | undefined
    currencyContractAddress?: string | undefined
    startTimestamp?: number | Date | undefined
    endTimestamp?: number | Date | undefined
    isReservedListing?: boolean | undefined
}

export class MarketplaceUtils {
    // READ

    public static async getPlatformFeeInfo(network: string, marketplaceContractAddress: string): Promise<any | null> {
        return await Utils.call(network, marketplaceContractAddress, 'getPlatformFeeInfo', [])
    }

    public static async getAllListings(network: string, marketplaceContractAddress: string, startId: number, endId: number): Promise<any | null> {
        return await Utils.call(network, marketplaceContractAddress, 'getAllListings', [startId, endId])
    }

    public static async getAllValidListings(
        network: string,
        marketplaceContractAddress: string,
        startId: number,
        endId: number
    ): Promise<any | null> {
        return await Utils.call(network, marketplaceContractAddress, 'getAllValidListings', [startId, endId])
    }

    public static async getListing(network: string, marketplaceContractAddress: string, listingId: number): Promise<any | null> {
        return await Utils.call(network, marketplaceContractAddress, 'getListing', [listingId])
    }

    public static async totalListings(network: string, marketplaceContractAddress: string): Promise<BigNumber | null> {
        return await Utils.call(network, marketplaceContractAddress, 'totalListings', [])
    }

    // WRITE

    public static async setPlatformFeeInfo(
        signer: ethers.Signer,
        marketplaceContractAddress: string,
        platformFeeRecipient: string,
        platformFeeBps: number
    ): Promise<any | null> {
        return await Utils.send(signer, marketplaceContractAddress, 'setPlatformFeeInfo', [platformFeeRecipient, platformFeeBps])
    }

    public static async approveBuyerForListing(
        signer: ethers.Signer,
        marketplaceContractAddress: string,
        listingId: number,
        buyer: string,
        toApprove: boolean
    ): Promise<any | null> {
        return await Utils.send(signer, marketplaceContractAddress, 'approveBuyerForListing', [listingId, buyer, toApprove])
    }

    public static async approveCurrencyForListing(
        signer: ethers.Signer,
        marketplaceContractAddress: string,
        listingId: number,
        currency: string,
        pricePerTokenInCurrency: BigNumber
    ): Promise<any | null> {
        return await Utils.send(signer, marketplaceContractAddress, 'approveCurrencyForListing', [listingId, currency, pricePerTokenInCurrency])
    }

    public static async buyFromListing(
        signer: ethers.Signer,
        marketplaceContractAddress: string,
        listingId: number,
        buyFor: string,
        quantity: number,
        currency: string,
        expectedTotalPrice: BigNumber,
        nativeTokenValue?: BigNumber
    ): Promise<any | null> {
        return await Utils.send(
            signer,
            marketplaceContractAddress,
            'buyFromListing',
            [listingId, buyFor, quantity, currency, expectedTotalPrice],
            nativeTokenValue
        )
    }

    public static async cancelListing(signer: ethers.Signer, marketplaceContractAddress: string, listingId: number): Promise<any | null> {
        return await Utils.send(signer, marketplaceContractAddress, 'cancelListing', [listingId])
    }

    public static async createListing(signer: ethers.Signer, marketplaceContractAddress: string, listing: Listing): Promise<any | null> {
        try {
            const sdk = await Utils.getSdkFromSigner(signer)

            const contract = await sdk.getContract(marketplaceContractAddress)

            return await contract.directListings.createListing(listing)
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress: marketplaceContractAddress,
                functionName: 'createListing',
                args: [listing],
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }

    public static async updateListing(
        signer: ethers.Signer,
        marketplaceContractAddress: string,
        listingId: number,
        listing: Listing
    ): Promise<any | null> {
        try {
            const sdk = await Utils.getSdkFromSigner(signer)

            const contract = await sdk.getContract(marketplaceContractAddress)

            return await contract.directListings.updateListing(listingId, listing)
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress: marketplaceContractAddress,
                functionName: 'updateListing',
                args: [listingId, listing],
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }
}
