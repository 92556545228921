import { metamaskWallet, useAddress, useConnect, useDisconnect, useMetamask, useWalletConfig } from '@thirdweb-dev/react'
import React, { FC, createContext, useEffect, useState } from 'react'
import { Modal, ModalOverlay, ModalBody, ModalContent, ModalCloseButton, Text, Box, Image, Flex } from '@chakra-ui/react'
import { useAppDispatch } from '../../redux/hook'
import { fetchSignIn } from '../auth/singInSlice'
import { ACCESS_TOKEN, PUBLIC_URL } from '../../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify';
import { resetUserProfile } from '../../components/ProfilePage/userProfileSlice'
import { resetNFTs } from '../../components/StakingNft/nftsStakingByCollectionSlice'
import { resetCollections } from '../../components/CollectionPage/collectionSlice'

interface Props {
    children: React.ReactNode
}

interface WalletContextType {
    openModalLogin: boolean;
    setOpenModalLogin: (open: boolean) => void;
    isLogin: string | null;
    logout: () => void;
    tokenExpiredCb: () => void;
    walletAddress: string
}

const defaultContextValue: WalletContextType = {
    openModalLogin: false,
    setOpenModalLogin: () => { },
    isLogin: null,
    logout: () => { },
    tokenExpiredCb: () => { },
    walletAddress: ''
};
export const WalletContext = createContext<WalletContextType>(defaultContextValue)

export const WalletProvider: FC<Props> = ({ children }) => {
    const intl = useIntl()
    const disconnect = useDisconnect()
    const location = useLocation()
    const navigate = useNavigate();
    const redirectPath = localStorage.getItem('redirectPath');

    const [openModalLogin, setOpenModalLogin] = useState(false)

    const onClose = () => {
        if (redirectPath) {
            setOpenModalLogin(false)
            localStorage.removeItem('redirectPath')
            navigate('/')
        } else {
            setOpenModalLogin(false)
        }
    }
    const metamaskConfig = metamaskWallet()
    const connectWithMetamask = useMetamask();
    const connect = useConnect()

    const walletConfig = useWalletConfig();
    const dispatch = useAppDispatch()
    const address = useAddress()

    const handleLogin = async () => {
        if (walletConfig?.meta?.name === 'MetaMask') {
            try {
                const result = await dispatch(fetchSignIn({
                    walletAddress: address!,
                    name: walletConfig?.meta?.name
                }))
                if (!result.payload) {
                    disconnect()
                    toast.warning(intl.formatMessage({ id: 'WALLET.REJECTED' }), { closeButton: false, })
                    return
                }
                setOpenModalLogin(false)
                setIsTokenExpired(false)
                dispatch(resetNFTs())
                dispatch(resetCollections())
                if (redirectPath) {
                    localStorage.removeItem('redirectPath');
                    navigate(redirectPath);
                } else {
                    navigate('/');
                }
            } catch (e) {
                disconnect()
            }
        }
    }

    const isLogin = localStorage.getItem(ACCESS_TOKEN)

    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false)

    const tokenExpiredCb = () => {
        setIsTokenExpired(true)
    }

    useEffect(() => {
        if (isTokenExpired) {
            logout2()
        }
    }, [isTokenExpired])


    useEffect(() => {
        if (address) {
            if (!isLogin) {
                handleLogin()
            }
        }
    }, [address, isLogin])

    const [walletAddress, setWalletAddress] = useState('')

    useEffect(() => {

        if (isLogin && address) {
            setWalletAddress(address)
        } else {
            setWalletAddress('')
        }
    }, [isLogin, address])

    useEffect(() => {
        const checkConnection = async () => {
            try {
                console?.log('Ethereum object is ready.', window.ethereum);

                const connectedAccounts = await window.ethereum.request({ method: 'eth_accounts' });
                if (connectedAccounts.length === 0) {
                    logout2();
                }
            } catch (error) {
                console.error('Error checking accounts:', error);
            } finally {
            }
        };

        if (!address && isLogin) {
            checkConnection();
        }
    }, [address, isLogin]);

    const logout = () => {
        disconnect()
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem('userData')
        localStorage.removeItem('redirectPath')
        dispatch(resetUserProfile())
        dispatch(resetNFTs())
        toast.success(intl.formatMessage({ id: 'WALLET.LOGOUT.SUCCESS' }), { closeButton: false, })
    }

    const logout2 = async () => {
        disconnect()
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem('userData')
        localStorage.removeItem('redirectPath')
        dispatch(resetUserProfile())
        dispatch(resetNFTs())
    }

    const logout3 = async () => {
        disconnect()
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem('userData')
        localStorage.removeItem('redirectPath')
        dispatch(resetUserProfile())
        dispatch(resetNFTs())
        toast.success(intl.formatMessage({ id: 'WALLET.LOGOUT.SUCCESS' }), {
            closeButton: false,
            autoClose: 1000,
            onClose: () => {
                window.location.reload();
            }
        });
    }

    useEffect(() => {
        if (location?.search === '?login') {
            if (!isLogin) {
                setOpenModalLogin(true)
            } else {
                setOpenModalLogin(false)
            }
        }
    }, [redirectPath, isLogin])

    useEffect(() => {
        const handleAccountsChanged = (accounts: string[]) => {
            if (accounts.length === 0 || (walletAddress && accounts[0] !== walletAddress)) {
                logout3()
            }
        };

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };
    }, [walletAddress, logout])

    return (
        <WalletContext.Provider value={{ openModalLogin, setOpenModalLogin, isLogin, logout, tokenExpiredCb, walletAddress }}>
            <Modal isCentered isOpen={openModalLogin} onClose={onClose}>
                <ModalOverlay
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='1%'
                    backdropBlur='1px'
                />
                <ModalContent bg={'#131619'}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box mt={'50px'} mb={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Image boxSize={{ base: '40px', lg: '100px' }} src="/images/logo.svg" />
                        </Box>
                        <Text maxWidth={'390px'} align={'center'} color={''} fontSize={'32px'}>{intl.formatMessage({ id: 'LOGIN.TITLE' })}</Text>
                        <Box my={'50px'}>
                            <Flex align={'center'} borderRadius={'6px'} p={'8px'} border={'1px'} borderColor={'#9d9b9b'} cursor={'pointer'}
                                onClick={async () => {
                                    try {
                                        if (metamaskConfig.isInstalled?.()) {
                                            await connectWithMetamask()
                                        }
                                        else {
                                            let redirect = null
                                            // if (redirect == null) {
                                            //     redirect = `${location.pathname}${location.search}`
                                            // }
                                            window.location.href = `https://metamask.app.link/dapp/${PUBLIC_URL}/?login`
                                        }
                                    }
                                    catch (e) {
                                        console.log("err", e)
                                    }
                                }}
                            >
                                <Image boxSize={{ base: '30px', lg: '30px' }} src="/images/metamask.svg" />
                                <Box fontSize={'18px'} fontWeight={500} ml={'10px'}>Metamask</Box>
                            </Flex>

                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {children}
        </WalletContext.Provider>)
}
