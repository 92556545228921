import { Box, CircularProgress, Container, Flex, Spinner, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { CardStaking } from '../../components/StakingNft/CardStaking';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { getNftsByCollection, resetNFTs } from '../../components/StakingNft/nftsStakingByCollectionSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WalletContext } from '../wallet/walletContext';
import { fetchUserProfile } from '../../components/ProfilePage/userProfileSlice';
import { NftType, StakingHistory } from '../../models/NftType';
import { getStatisticsStakingApi, saveDataStakingApi, signTheStakingRequestApi, unStakingApi } from '../../setup/api/apiStaking';
import { toast } from 'react-toastify';
import { NFTUtils, StakingUtils } from '../wallet';
import { SECONDS_IN_A_DAY, STAKING_CONTRACT_ADDRESS, STAKING_STATUS, chainIds } from '../../constants';
import { useChainId, useSigner, useSwitchChain } from '@thirdweb-dev/react';
import { NotiUnstakingModal } from '../../components/StakingNft/NotiUnstakingModal';
import { StatisticsStakingType } from '../../models/StakingType';
import { formatPositiveNumber } from '../../helpers/crud-helper/helpers';

export const DEFAULT_TAKE = 20
export const DEFAULT_SKIP = 0

export const NftStakingPage = () => {
    const intl = useIntl();
    const { isLogin, tokenExpiredCb } = useContext(WalletContext)
    const [isLg] = useMediaQuery('(min-width: 992px)')
    const { network, contractId } = useParams()
    const dispatch = useAppDispatch()

    const { data: nftsStakingData } = useAppSelector((state) => state.nftsStakingData)
    const { data: userData } = useAppSelector((state) => state.userProfile)
    const [reloadData, setReloadData] = useState(false)

    const onSearch = (address?: string) => {
        dispatch(
            getNftsByCollection({
                take: DEFAULT_TAKE,
                skip: reloadData ? DEFAULT_SKIP : (nftsStakingData?.options?.currentPage || 0) * DEFAULT_TAKE,
                contractAddress: contractId,
                network: network,
                walletAddress: address
            })
        )
        setReloadData(false)
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!network || !contractId) return
        if (userData?.walletAddress) {
            (async () => {
                onSearch(userData?.walletAddress)
                return
            })()

        } else {
            if (isLogin) return
            onSearch()
        }

        return () => {
            dispatch(resetNFTs())
        }

    }, [network, contractId, reloadData, userData?.walletAddress])


    useEffect(() => {
        if (!network || !contractId || !isLogin) return
        dispatch(fetchUserProfile(tokenExpiredCb))

    }, [network, contractId, isLogin])

    const [statictisticsDataStaking, setStatictisticsDataStaking] = useState<StatisticsStakingType>()

    useEffect(() => {
        if (!isLogin || !userData?.walletAddress || !network) return
        (async () => {
            const statistics = await getStatisticsStakingApi(network)

            setStatictisticsDataStaking(statistics)
        })()

    }, [isLogin, userData?.walletAddress, reloadData])



    const [loadingStaking, setLoadingStaking] = useState(false);
    const [loadingUnStaking, setLoadingUnStaking] = useState(false);
    const [stakingData, setStakingData] = useState<NftType | null>(null);
    const [unStakingData, setUnStakingData] = useState<NftType | null>(null);
    const [openNotiUnstake, setOpenNotiUnstake] = useState(false)

    const onCloseNotiUnstake = () => setOpenNotiUnstake(false)

    const chain = useChainId()
    const switchChain = useSwitchChain()
    const signer = useSigner()

    const handleStaking = async (nftData: NftType) => {
        setLoadingStaking(true);
        setStakingData(nftData);
        try {
            const isApproved = await NFTUtils?.getApproved(nftData?.collection?.network, nftData?.contractAddress as string, Number(nftData?.tokenId))
            
            //switchChain

            if (chainIds[nftData?.collection?.network] !== chain) {
                await switchChain(chainIds[nftData?.collection?.network])
            }

            isApproved !== STAKING_CONTRACT_ADDRESS && await NFTUtils?.approve(signer as any, nftData?.contractAddress as string, STAKING_CONTRACT_ADDRESS, Number(nftData?.tokenId))

            const signData = await signTheStakingRequestApi({
                network: nftData?.collection?.network,
                contractAddress: nftData?.contractAddress,
                tokenId: nftData?.tokenId
            })
            

            if (!signData?.data) {
                toast?.error(intl.formatMessage({ id: 'NFT.REFERRAL.CODE.INVALID' }), { closeButton: false, })
                return
            }
            const result = await StakingUtils?.stake(signer as any, STAKING_CONTRACT_ADDRESS, {
                nftAddress: signData?.data?.contractAddress,
                tokenId: signData?.data?.tokenId,
                price: signData?.data?.price?.toLocaleString('fullwide', {useGrouping:false}),
                expiryDate: signData?.data?.expiryDate,
                signature: signData?.data?.signature
            })


            if (result?.receipt.status) {
                //save api
                await saveDataStakingApi({
                    network: nftData?.collection?.network,
                    contractAddress: nftData?.contractAddress,
                    tokenId: nftData?.tokenId,
                    transactionHash: result?.receipt?.transactionHash,
                    stakingId: result?.id?.toNumber(),
                })
                setReloadData(true)
                setLoadingStaking(false)
                setStakingData(null)
                toast?.success(intl.formatMessage({ id: 'NFT.STAKING.SUCCESS' }), { closeButton: false, })

            } else {
                toast?.error('error')
                setLoadingStaking(false)
                setStakingData(null)
            }


        } catch (error: any) {
            setLoadingStaking(false)
            setStakingData(null)
            if (error?.reason === 'ERC721: approval to current owner') {
                toast?.error(intl.formatMessage({ id: 'ERROR.ERC721.APPROVAL_TO_CURRENT_OWNER' }))
                return
            } else {
                toast?.error(error?.reason || 'unknown error')
                return
            }
        }
    };

    const handleUnStaking = async (nftData: NftType, highestStaking: StakingHistory) => {
        setLoadingUnStaking(true)
        setUnStakingData(nftData)
        try {
            //switchChain

            if (chainIds[nftData?.collection?.network] !== chain) {
                await switchChain(chainIds[nftData?.collection?.network])
            }

            const result = await StakingUtils?.unstake(signer as any, STAKING_CONTRACT_ADDRESS, highestStaking?.stakingId)

            if (result?.receipt.status) {
                //save api
                await unStakingApi({
                    network: nftData?.collection?.network,
                    contractAddress: nftData?.contractAddress,
                    tokenId: nftData?.tokenId,
                    transactionHash: result?.receipt?.transactionHash
                })

                if (highestStaking?.status === STAKING_STATUS?.STAKED) {
                    setReloadData(true)
                    setLoadingUnStaking(false)
                    setUnStakingData(null)
                    setOpenNotiUnstake(true)
                    return
                }
                setReloadData(true)
                setLoadingUnStaking(false)
                setUnStakingData(null)
                toast?.success(intl.formatMessage({ id: 'NFT.UNSTAKING.SUCCESS' }), { closeButton: false, })

            } else {
                toast?.error('error')
                setLoadingUnStaking(false)
                setUnStakingData(null)
            }


        } catch (error: any) {
            setLoadingUnStaking(false)
            setUnStakingData(null)
            if (error?.reason) {
                toast?.error(error?.reason || 'unknown error')
                return
            }
        }
    }

    const [timeUnstaking, setTimeUnstaking] = useState<number>(0)

    useEffect(() => {
        if (!network || !isLogin) return
        try {
            (async () => {
                const timeUnstaking = await StakingUtils.unstakeWaitingTime(network, STAKING_CONTRACT_ADDRESS)
                setTimeUnstaking(timeUnstaking?.toNumber() || 0)

            })()

        } catch (error) {

        }
    }, [network, isLogin])


    return (
        <Container maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0}>
            {isLogin &&
                <Flex flexDirection={{ base: 'column', lg: 'row' }} borderRadius={'6px'} justify={'center'} align={'center'} backgroundColor={'#FFFFFF0A'} px={{ base: '24px', lg: '32px' }} py={{ base: '12px', lg: '32px' }} flexWrap={'wrap'}>
                    <Box w={'100%'} flex="1" borderRight={{ base: 'none', lg: '1px solid #27282A' }} borderBottom={{ lg: 'none', base: '1px solid #27282A' }}>
                        <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                            <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'NFT.STAKING.TOTAL.NFT.PRICE' })}</Text>
                            <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{!statictisticsDataStaking ? <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='gray.500' /> : statictisticsDataStaking?.totalStakingPrice || 0}</Text>
                        </Box>
                    </Box>
                    <Box w={'100%'} flex="1">
                        <Box py={{ base: '16px', lg: 0 }} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                            <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={400} fontSize={{ lg: '16px', base: '16px' }} color={'#808489'}>{intl.formatMessage({ id: 'NFT.STAKING.APR' }, { apy: statictisticsDataStaking ? formatPositiveNumber(statictisticsDataStaking?.apy / 12) : 0 })}</Text>
                            <Text textAlign={{ lg: 'center', base: 'start' }} w={'100%'} fontWeight={700} fontSize={{ lg: '24px', base: '20px' }}>{statictisticsDataStaking ? formatPositiveNumber(statictisticsDataStaking?.apy) : 0} %</Text>
                        </Box>
                    </Box>
                </Flex>
            }
            {nftsStakingData && nftsStakingData.data?.length > 0 ? (
                <InfiniteScroll
                    dataLength={nftsStakingData.data.length}
                    next={onSearch}
                    hasMore={nftsStakingData.data.length < (nftsStakingData?.options?.totalItems as number)}
                    style={{
                        marginTop: isLg ? '40px' : '24px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: isLg ? '32px' : '12px',
                        justifyContent: isLg ? 'start' : 'space-between',
                    }}
                    loader={<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />}
                >
                    {nftsStakingData.data?.map(item => (
                        <CardStaking
                            key={item?.id}
                            nftData={item}
                            userData={userData}
                            loadingStaking={loadingStaking && stakingData?.id === item?.id}
                            loadingUnStaking={loadingUnStaking && unStakingData?.id === item?.id}
                            onStaking={handleStaking}
                            unStaking={handleUnStaking}
                            stakingId={stakingData?.id || ''}
                            disabledStaking={Boolean(stakingData) && stakingData?.id !== item?.id}
                            disabledUnStaking={Boolean(unStakingData) && unStakingData?.id !== item?.id}
                            timeUnstaking={timeUnstaking}
                        />
                    ))}
                </InfiniteScroll>
            ) : (
                <>
                    <Box margin={'auto'}>
                        <Text>{intl.formatMessage({ id: 'COLLECTION.NFT.NO_NFT' })}</Text>
                    </Box>
                </>
            )}
            {openNotiUnstake && <NotiUnstakingModal timeUnstaking={formatPositiveNumber(timeUnstaking / SECONDS_IN_A_DAY)} openModal={openNotiUnstake} onCloseModal={onCloseNotiUnstake} />}
        </Container>
    )
}
