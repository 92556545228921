import { LOG_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"

export const saveLogDataApi = async (message: string): Promise<any> => {
    try {
        const params = {
            message
        }
        const resp = await Api.AuthService.POST(LOG_SERVICE.SAVE_LOG_DATA, params);
        return resp?.data
    } catch (error) {}
}

