import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import Api from '../../setup/axios/SetupAxios'
import { COLLECTION_SERVICE } from '../../constants'
import { objToQueryString } from '../../helpers/crud-helper/helpers'
import { CollectionType } from '../../models/CollectionType'

export const DEFAULT_TAKE = 10
export const DEFAULT_SKIP = 0

type NFTReq = {
    take?: number
    skip?: number
    name?: string
    categorySlug?: string
    isStaking?: boolean
    network?: string
    walletAddress?: string
}

export const getCollectionPublicApi = async (data?: NFTReq): Promise<any> => {
    try {
        const params = {
            ...(data?.take ? { take: data?.take } : {}),
            ...(data?.skip ? { skip: data?.skip } : {}),
            ...(data?.isStaking ? { isStaking: data?.isStaking } : {}),
            ...(data?.categorySlug ? { categorySlug: data?.categorySlug } : {}),
            ...(data?.name ? { name: data?.name } : {}),
            ...(data?.network ? { network: data?.network } : {}),
            ...(data?.walletAddress ? { walletAddress: data?.walletAddress } : {}),
        }
        return Api.AuthService.GET(COLLECTION_SERVICE.GET_COLLECTION_PUBLIC + '?' + objToQueryString(params));

    } catch (error) {
        throw error
    }
}

export const getAllCollectionForCategory = createAsyncThunk('nft/getAllCollectionForCategory', async (req?: NFTReq) => {
    const resp = await getCollectionPublicApi(req)
    return resp?.data
})

export const resetCollections = createAction('nft/resetCollections')

type PaginationType<T> = {
    data: T
    options: {
        currentPage: number
        totalItems: number
        totalPages: number
    }
}

type InitialState = {
    loading: boolean
    data: PaginationType<CollectionType[]> | null
    errors: any
}



const initialState: InitialState = {
    loading: false,
    data: null,
    errors: null,
}

const collectionSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCollectionForCategory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllCollectionForCategory.fulfilled, (state, action) => {
            state.loading = false
            const newData = (action.payload?.data as CollectionType[])?.filter(
                (payloadItem) => !state.data?.data.some((stateItem) => stateItem.id === payloadItem.id)
            )
            state.data = {
                data: [...(state?.data?.data || []), ...newData],
                options: action.payload?.options || {},
            }
        })
        builder.addCase(getAllCollectionForCategory.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.errors = action?.error?.message || 'Something went wrong'
        })
        builder.addCase(resetCollections, (state, action) => {
            return initialState
        })
    },
})

export default collectionSlice.reducer
