import { NFT_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"

interface Setting {
    price?: number | null
    currency?: string
    currencyAddress?: string
    listingType?: string
    quantity?: number
    startTime?: string
    endTime?: string
    listingId?: number
}

interface ListingNftReq {
    network?: string
    contractAddress?: string;
    tokenId?: string;
    setting?: Setting;
}

interface CancelListingNftReq {
    network?: string
    contractAddress?: string;
    tokenId?: string;
}

export const createListingNftApi = async (data?: ListingNftReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId,
            setting: {
                price: data?.setting?.price,
                currency: data?.setting?.currency,
                currencyAddress: data?.setting?.currencyAddress,
                listingType: data?.setting?.listingType,
                quantity: data?.setting?.quantity,
                startTime: data?.setting?.startTime,
                endTime: data?.setting?.endTime,
                listingId: data?.setting?.listingId
            }
        }
        const resp = await Api.AuthService.POST(NFT_SERVICE.CREATE_LISTING_NFT, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const cancelListingNftApi = async (data?: CancelListingNftReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId
        }
        const resp = await Api.AuthService.POST(NFT_SERVICE.CANCEL_LISTING_NFT, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}
