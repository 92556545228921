import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import Api from '../../setup/axios/SetupAxios'
import { NFT_SERVICE } from '../../constants'
import { NftType, PaginationType } from '../../models/NftType'
import { cleanObject, objToQueryString } from '../../helpers/crud-helper/helpers'

export enum SortType {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const DEFAULT_TAKE = 10
export const DEFAULT_SKIP = 0

type NFTReq = {
    take?: number
    skip?: number
    name?: string
    contractAddress?: string
    sortType?: SortType
    network?: string
    isAll?: boolean
    sortBy?: string
    walletAddress?: string
}

export const getNFTsByCollection = (req?: NFTReq) => {
    const params = {
        ...(req?.take ? { take: req?.take } : {}),
        ...(req?.skip ? { skip: req?.skip } : {}),
        ...(req?.name ? { name: req?.name } : {}),
        ...(req?.network ? { network: req?.network } : {}),
        ...(req?.sortType ? { sortType: req?.sortType } : {}),
        ...(req?.isAll ? { isAll: req?.isAll } : {}),
        ...(req?.sortBy ? { sortBy: req?.sortBy } : {}),
        ...(req?.walletAddress ? { walletAddress: req?.walletAddress } : {}),
        contractAddress: req?.contractAddress
    }
    return Api.NFTService.GET(NFT_SERVICE.GET_NFT_PUBLIC + '?' + objToQueryString(params))
}

export const getNftsByCollection = createAsyncThunk('nftStaking/getNftsByCollection', async (req?: NFTReq) => {
    const resp = await getNFTsByCollection(req)
    return resp?.data
})

export const resetNFTs = createAction('nftStaking/resetNFTs')

type InitialState = {
    loading: boolean
    data: PaginationType<NftType[]> | null
    errors: any
}

const initialState: InitialState = {
    loading: false,
    data: null,
    errors: null,
}

const nftsStakingByCollectionSlice = createSlice({
    name: 'nftStaking',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNftsByCollection.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getNftsByCollection.fulfilled, (state, action) => {
            state.loading = false
            const newData = (action.payload?.data as NftType[])?.filter(
                (payloadItem) => !state.data?.data.some((stateItem) => stateItem.id === payloadItem.id)
            )
            state.data = {
                data: [...(state.data?.data || []), ...newData],
                options: action.payload?.options || {},
            }
        })
        builder.addCase(getNftsByCollection.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.errors = action?.error?.message || 'Something went wrong'
        })
        builder.addCase(resetNFTs, (state, action) => {
            return initialState
        })
    },
})

export default nftsStakingByCollectionSlice.reducer
