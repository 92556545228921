import { STAKING_SERVICE } from "../../constants";
import { objToQueryString } from "../../helpers/crud-helper/helpers";
import { ClaimRewardReq, DataStakingReq, SignRequestStakingReq, SignRequestStakingRes, StakingListReq, StakingListType, StatisticsStakingType, UnStakingReq } from "../../models/StakingType";
import Api from "../axios/SetupAxios"

export const signTheStakingRequestApi = async (data?: SignRequestStakingReq): Promise<SignRequestStakingRes> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId
        }
        const resp = await Api.AuthService.POST(STAKING_SERVICE.SIGN_REQUEST_STAKING, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const saveDataStakingApi = async (data?: DataStakingReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId,
            transactionHash: data?.transactionHash,
            stakingId: data?.stakingId
        }
        const resp = await Api.AuthService.POST(STAKING_SERVICE.SAVE_STAKING_DATA, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const unStakingApi = async (data?: UnStakingReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId,
            transactionHash: data?.transactionHash
        }
        const resp = await Api.AuthService.POST(STAKING_SERVICE.UNSTAKE_DATA, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const getStatisticsStakingApi = async (network?: string): Promise<StatisticsStakingType> => {
    try {
        const resp = await Api.AuthService.GET(STAKING_SERVICE.GET_STAKING_STATISTICS + '?' + objToQueryString({ network }));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}


export const claimRewardApi = async (data?: ClaimRewardReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId,
            transactionHash: data?.transactionHash,
            reward: data?.reward
        }
        const resp = await Api.AuthService.POST(STAKING_SERVICE.CLAIM_REWARD, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const getStakingListApi = async (data?: StakingListReq): Promise<StakingListType[]> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId
        }
        const resp = await Api.AuthService.GET(STAKING_SERVICE.GET_STAKING_LIST + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}


