import { ReactNode } from 'react';
import { useLang } from './I18nContext'
import { IntlProvider } from 'react-intl'
// import '@formatjs/intl-relativetimeformat/polyfill'
// import '@formatjs/intl-relativetimeformat/locale-data/en'
// import '@formatjs/intl-relativetimeformat/locale-data/vn'


interface Messages {
  [key: string]: string;
}

const enMessages: Messages = require('./message/en.json');
const jaMessages: Messages = require('./message/ja.json');

const allMessages: Record<string, Messages> = {
  en: enMessages,
  ja: jaMessages,
}

interface I18nProviderProps {
  children: ReactNode;
}

const I18nProvider = ({ children }: I18nProviderProps) => {
  const { selectedLang } = useLang();
  const messages = allMessages[selectedLang]
  return (
    <IntlProvider locale={selectedLang} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
