export type AffiliateType = {
    affiliateFee?: string
    claimStatus?: string
    month?: number
    year?: number
    withdrawalMonth?: number
    withdrawalYear?: number
}

export type AffiliateStatisticsType = {
    numberOfReferrals?: number
    numberOfBuyers?: number
    currentAffiliateRate?: number
    totalAffiliateReward?: string
}

export enum ClaimStatus {
    CLOSE = 'close',
    OPEN = 'open',
    PENDING = 'pending',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
}

export type WithdrawReq = {
    month?: number
    year?: number
    network: string
}
