
import { NFT_SERVICE } from "../../constants";
import { objToQueryString } from "../../helpers/crud-helper/helpers";
import { NftDetailReq, NftDetailType } from "../../models/NftType";
import Api from "../axios/SetupAxios"



export const getNftDetailApi = async (data: NftDetailReq): Promise<NftDetailType> => {
    try {
        const params = {
            ...(data?.network ? { network: data?.network } : {}),
            ...(data?.contractAddress ? { contractAddress: data?.contractAddress } : {}),
            ...(data?.tokenId ? { tokenId: data?.tokenId } : {})
        }
        const resp: any = await Api.PublicService.GET(NFT_SERVICE.GET_TOKEN_DETAILS + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}