export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
export const MARKETPLACE_CONTRACT_ADDRESS = process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS || '0xc8C9435dE094dDD590787D611D392889a0a2AF79'
export const STAKING_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_STAKING_CONTRACT_ADDRESS || '0x87F0c1A66B61d3f6A133c3218D8D93Dcd162BC0f'
export const USDT_CONTRACT_ADDRESS = process.env.REACT_APP_USDT_CONTRACT_ADDRESS || '0x7b5FbFc0E91c06c170e0634Ee8e2Dc3d5e0c0c4f'
export const USDT_CURRENCY = 'USDT'
export const ACCESS_TOKEN = 'access_token'
export const PUBLIC_URL = process.env.PUBLIC_URL
export const MARKETPLACE_SUPPORT_NETWORK = ['polygon', 'polygon-amoy-testnet']
export const SUPPORT_NETWORK = process.env.REACT_APP_SUPPORT_NETWORK || 'polygon-amoy-testnet'
export const CLIENDT_ID = process.env.REACT_APP_THIRDWEB_CLIENT_ID || "5b2c848ab7fa5086d2969a13e5b70d39"
export const USDT_DECIMALS = process.env.REACT_APP_USDT_DECIMALS || 6

export const AUTH_SERVICE = {
    SING_IN: 'auth/sign-in-with-crypto-wallet',
    GET_NONCE: 'app/user/nonce',
    ACCESS_TOKEN: 'login',
    REFRESH_TOKEN: 'refreshtoken',
    GET_USER: 'app/user/me',
    UPDATE_USER: 'app/user',
    SYNC_COLLECTION_AND_NFT: 'app/user/sync-collections',
    CHECK_REFERRAL_CODE: 'app/user/check-referral-code',

}

export const AFFILIATE_SERVICE = {
    GET_AFFILIATE_LIST: 'app/affiliate/get-affiliate-list',
    GET_AFFILIATE_STATISTICS: 'app/affiliate/get-affiliate-statistics',
    WITHDRAWAL_AFFILIATE: 'app/affiliate/withdraw',
    GET_CONFIG: 'app/config',
}

export const COLLECTION_SERVICE = {
    GET_COLLECTION_PUBLIC: 'app/collection',
    GET_COLLECTION_PUBLIC_DETAIL: 'app/collection/get-collection-details',
    GET_COLLECTION_PUBLIC_STATISTICS: 'app/collection/get-collection-statistics',
    SYNC_OWNERS: 'app/collection/sync-owners',
}

export const NFT_SERVICE = {
    GET_NFT_PUBLIC: 'app/token',
    GET_MY_NFT: 'app/token/my-nfts',
    GET_TOKEN_DETAILS: 'app/token/get-token-detailS',
    CREATE_LISTING_NFT: 'app/token/create-listing',
    SAVE_TRANSACTION_NFT: 'app/token/save-transaction-info',
    CANCEL_LISTING_NFT: 'app/token/cancel-listing',
    SYNC_OWNERS: 'app/token/sync-owners',
}

export const PRICE_SERVICE = {
    GET_NFT_PUBLIC: 'v3/simple/price'
}

export const STAKING_SERVICE = {
    SAVE_STAKING_DATA: 'app/staking/stake',
    SIGN_REQUEST_STAKING: 'app/staking/sign-the-staking-request',
    UNSTAKE_DATA: 'app/staking/unstake',
    GET_STAKING_STATISTICS: 'app/staking/get-staking-statistics',
    CLAIM_REWARD: 'app/staking/claim',
    GET_STAKING_LIST: 'app/staking/get-staking-list',

}

export const LOG_SERVICE = {
    SAVE_LOG_DATA: 'log',
}

export const MONTH = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const CURRENCY: { [key: string]: string } = {
    'polygon-amoy-testnet': 'MATIC',
    'polygon': 'MATIC'
}

export const CURRENCY_ADDRESS: { [key: string]: string } = {
    'polygon-amoy-testnet': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    'polygon': '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
}

export const chainIds: { [key: string]: number } = {
    'polygon-amoy-testnet': 80002,
    'polygon': 137
}

export const IDS: { [key: string]: string } = {
    'polygon-amoy-testnet': 'matic-network',
    'polygon': 'matic-network'
}

export const STAKING_STATUS = {
    'STAKED': 'staked',
    'UNSTAKED': 'unstaked',
    'UNSTAKING': 'unstaking'
}

export const AFFLIATE_TYPE = {
    RATE: "affiliate-rate",
    TIME_DAY: "number-of-days-to-transfer-commission"
}

export const SECONDS_IN_A_DAY = 86400

export const URL_COLLECTION_DEFAULT = '/images/example/collection-default.png'
