import { ThirdwebSDK } from '@thirdweb-dev/sdk'
import { BigNumber, ethers } from 'ethers'
import { Interface } from 'ethers/lib/utils'
import { saveLogDataApi } from '../../setup/api/apiLog'

export class Utils {
    public static async call(network: string, contractAddress: string, functionName: string, args: any[]): Promise<any | null> {
        const sdk = this.getSdk(network)

        const contract = await sdk.getContract(contractAddress)

        return await contract.call(functionName, args)
    }

    public static async send(
        signer: ethers.Signer,
        contractAddress: string,
        functionName: string,
        args: any[],
        nativeTokenValue?: BigNumber
    ): Promise<any | null> {
        try {
            const sdk = await this.getSdkFromSigner(signer)

            const contract = await sdk.getContract(contractAddress)

            return await contract.call(functionName, args, { value: nativeTokenValue || 0 })
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress,
                functionName,
                args,
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }

    public static async deploy(
        signer: ethers.Signer,
        implementationContractAddress: string,
        implementationAbi: Interface,
        initializerFunction: string,
        initializerArgs: any[]
    ): Promise<string | null> {
        const sdk = await this.getSdkFromSigner(signer)

        return await sdk.deployer.deployViaFactory(
            process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS || '',
            implementationContractAddress,
            implementationAbi,
            initializerFunction,
            initializerArgs
        )
    }

    public static async getSdkFromSigner(signer: ethers.Signer): Promise<ThirdwebSDK> {
        return ThirdwebSDK.fromSigner(signer, await signer.getChainId())
    }

    public static getSdk(network: string): ThirdwebSDK {
        return new ThirdwebSDK(network, { secretKey: process.env.THIRDWEB_SECRET_KEY })
    }
}
