import { Container } from '@chakra-ui/react'
import React from 'react'
import { CollectionTrending } from './HomePage/CollectionTrending'
import { CollectionTop } from './HomePage/CollectionTop'
import { TrenddingInGamingTopPage } from './HomePage/TrenddingInGamingTopPage'
import { TrenddingInArtTopPage } from './HomePage/TrenddingInArtTopPage'
import { StakingListTopPage } from './HomePage/StakingListTopPage'


export const MainHomePage = () => {

    return (
        <Container maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0} >
            {/* //collection trending */}
            <CollectionTrending />
            {/* //collection */}
            <CollectionTop />
            {/* //trending in game */}
            <TrenddingInGamingTopPage />
            {/* //trending in art */}
            <TrenddingInArtTopPage />
            {/* //staking list*/}
            <StakingListTopPage />

        </Container>
    )
}
