import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { BasicLayout } from './BasicLayout'
import { AccountPage } from '../pages/AccountPage'
import { AccountSettingPage } from '../pages/AccountSettingPage'
import { AccountAffliatePage } from '../pages/AccountAffliatePage'
const PrivateRoutes = () => {

    return (
        <Routes>
            <Route element={<BasicLayout />}>
                <Route
                    path='account/*'
                    element={
                        <AccountPage />
                    }
                />
                <Route
                    path='account/settings'
                    element={
                        <AccountSettingPage />
                    }
                />
                <Route
                    path='account/affiliate'
                    element={
                        <AccountAffliatePage />
                    }
                />
            </Route>
        </Routes>
    )
}

export { PrivateRoutes }
