import { DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Drawer as ChakraDrawer } from '@chakra-ui/react'

interface IDrawerProps {
    onClose: () => void
    isOpen: boolean
    title: string
    placement: 'top' | 'right' | 'bottom' | 'left'
    children: React.ReactNode
    hContent?: string
    bgContent?: string
}
export const Drawer = (props: IDrawerProps) => {
    const { onClose, isOpen, children, hContent, bgContent, placement, title } = props
    return (
        <ChakraDrawer placement={placement} onClose={onClose} isOpen={isOpen} preserveScrollBarGap={true} autoFocus={false}>
            <DrawerOverlay />
            <DrawerContent h={hContent} backgroundColor={bgContent}>
                <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
            </DrawerContent>
        </ChakraDrawer>
    )
}
