
import { AUTH_SERVICE, COLLECTION_SERVICE, NFT_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"



export const syncCollectionApi = async ({ network, tokenExpiredCb }: { network: string, tokenExpiredCb?: () => void }) => {
    try {
        const params = {
            network: network
        }
        const resp: any = await Api.AuthService.POST(AUTH_SERVICE.SYNC_COLLECTION_AND_NFT, params);
        if (resp?.errors?.[0]?.code === 4141) {
            tokenExpiredCb?.()
        }
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const syncOwnersCollectionApi = async ({ network, contractAddress, tokenExpiredCb }: { network: string, contractAddress: string, tokenExpiredCb?: () => void }) => {
    try {
        const params = {
            network: network,
            contractAddress: contractAddress
        }
        const resp: any = await Api.AuthService.POST(COLLECTION_SERVICE?.SYNC_OWNERS, params);
        if (resp?.errors?.[0]?.code === 4141) {
            tokenExpiredCb?.()
        }
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const syncOwnersNftApi = async ({ network, contractAddress, tokenId, tokenExpiredCb }: { network: string, contractAddress: string, tokenId: string, tokenExpiredCb?: () => void }) => {
    try {
        const params = {
            network,
            contractAddress,
            tokenId
        }
        const resp: any = await Api.AuthService.POST(NFT_SERVICE?.SYNC_OWNERS, params);
        if (resp?.errors?.[0]?.code === 4141) {
            tokenExpiredCb?.()
        }
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}