import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./styles/globals.css";
import { AppRoutes } from "./app/routing/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import "./assets/scss/index.scss";
import { ChakraProvider } from '@chakra-ui/react'
import { chakraCustomTheme } from "./chakra-ui/theme";
import { I18nProvider } from "./i18/i18Provider";
import { I18nContextProvider } from "./i18/I18nContext";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <I18nContextProvider>
        <Provider store={store}>
            <I18nProvider>
                <ChakraProvider theme={chakraCustomTheme} >
                    <BrowserRouter>
                        <AppRoutes />
                        <ToastContainer
                            position="bottom-right"
                            hideProgressBar={true}
                            rtl={false}
                        />
                    </BrowserRouter>
                </ChakraProvider>
            </I18nProvider>
        </Provider>
    </I18nContextProvider>
);

reportWebVitals();
