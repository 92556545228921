import { Grid, Box, Image, Text, GridItem } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { CollectionType } from '../../models/CollectionType'
import { useIntl } from 'react-intl'
import { formatNumber } from '../../helpers/crud-helper/helpers'
import { URL_COLLECTION_DEFAULT } from '../../constants'


type Props = {
    collectionData: CollectionType
}

export const CardCollection: FC<Props> = ({ collectionData }) => {
    const intl = useIntl()
    return (
        <Box key={collectionData?.id} borderRadius={'6px'} w='100%' bg='#FFFFFF0A' >
            <Link to={`/collection/${collectionData?.network}/${collectionData?.contractAddress}`}>
                <Image borderTopRadius={'6px'} objectFit={'cover'} w={'100%'} h={{ base: '204px', lg: '304px' }} src={collectionData?.logo || URL_COLLECTION_DEFAULT} />
                <Box p={{ base: '16px', lg: '24px' }}>
                    <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} fontSize={{ base: '16px', lg: '18px' }} fontWeight={700}>{collectionData?.name}</Text>
                    <Grid mt={{ base: '12px', lg: '16px' }} templateColumns='repeat(2, 1fr)' gap={4}>
                        <GridItem overflow={'hidden'} textOverflow={'ellipsis'}>
                            <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} fontSize={'14px'} fontWeight={400} color={'#808489'}>{intl.formatMessage({ id: 'HOME.TRENDING.FLOOR' })}</Text>
                            <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} mt={{ base: '4px', lg: '8px' }} fontSize={{ base: '16px', lg: '18px' }} fontWeight={700}>{formatNumber(collectionData?.floorPriceValue, 0.001)} {collectionData?.floorPriceCurrency}</Text>
                        </GridItem>
                        <GridItem>
                            <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} fontSize={'14px'} fontWeight={400} color={'#808489'}>{intl.formatMessage({ id: 'HOME.TRENDING.VOLUME' })}</Text>
                            <Text overflow={'hidden'} textOverflow={'ellipsis'} style={{ textWrap: 'nowrap' }} mt={{ base: '4px', lg: '8px' }} fontSize={{ base: '16px', lg: '18px' }} fontWeight={700}>{formatNumber(collectionData?.volumeValue, 0.001)} {collectionData?.volumeCurrency}</Text>
                        </GridItem>
                    </Grid>
                </Box>
            </Link>
        </Box>
    )
}
