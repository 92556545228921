import { BigNumber, ethers } from 'ethers'
import { Utils } from './utils'
import Erc20Abi from './abi/ERC20.abi.json'
import { saveLogDataApi } from '../../setup/api/apiLog'

export class TokenUtils {
    // READ

    public static async getName(network: string, tokenContractAddress: string): Promise<string | null> {
        return await this.call(network, tokenContractAddress, 'name', [])
    }

    public static async symbol(network: string, tokenContractAddress: string): Promise<string | null> {
        return await this.call(network, tokenContractAddress, 'symbol', [])
    }

    public static async decimals(network: string, tokenContractAddress: string): Promise<BigNumber | null> {
        return await this.call(network, tokenContractAddress, 'decimals', [])
    }

    public static async totalSupply(network: string, tokenContractAddress: string): Promise<BigNumber | null> {
        return await this.call(network, tokenContractAddress, 'totalSupply', [])
    }

    public static async allowance(network: string, tokenContractAddress: string, owner: string, spender: string): Promise<BigNumber | null> {
        return await this.call(network, tokenContractAddress, 'allowance', [owner, spender])
    }

    public static async balanceOf(network: string, tokenContractAddress: string, account: number): Promise<BigNumber | null> {
        return await this.call(network, tokenContractAddress, 'balanceOf', [account])
    }

    // WRITE

    public static async approve(signer: ethers.Signer, tokenContractAddress: string, spender: string, amount: BigNumber): Promise<string | null> {
        return await this.send(signer, tokenContractAddress, 'approve', [spender, amount])
    }

    public static async transfer(signer: ethers.Signer, tokenContractAddress: string, recipient: string, amount: BigNumber): Promise<string | null> {
        return await this.send(signer, tokenContractAddress, 'transferFrom', [recipient, amount])
    }

    public static async transferFrom(
        signer: ethers.Signer,
        tokenContractAddress: string,
        sender: string,
        recipient: string,
        amount: BigNumber
    ): Promise<string | null> {
        return await this.send(signer, tokenContractAddress, 'transferFrom', [sender, recipient, amount])
    }

    public static async call(network: string, contractAddress: string, functionName: string, args: any[]): Promise<any | null> {
        const sdk = Utils.getSdk(network)

        const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(Erc20Abi))

        return await contract.call(functionName, args)
    }

    public static async send(
        signer: ethers.Signer,
        contractAddress: string,
        functionName: string,
        args: any[],
        nativeTokenValue?: BigNumber
    ): Promise<any | null> {
        try {
            const sdk = await Utils.getSdkFromSigner(signer)

            const contract = await sdk.getContractFromAbi(contractAddress, JSON.stringify(Erc20Abi))

            return await contract.call(functionName, args, { value: nativeTokenValue || 0 })
        } catch (error: any) {
            await saveLogDataApi(JSON.stringify({
                signer: await signer.getAddress(),
                contractAddress,
                functionName,
                args,
                error: error.reason || 'unknown',
            }))
            throw error
        }
    }
}
