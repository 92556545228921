import { NFT_SERVICE } from "../../constants";
import Api from "../axios/SetupAxios"

interface BuyerTransactionNftReq {
    network?: string
    contractAddress?: string
    tokenId?: string
    transactionHash?: string
    marketplaceContractAddress?: string
    referralCode?: string
}

export const saveBuyerNftApi = async (data?: BuyerTransactionNftReq): Promise<any> => {
    try {
        const params = {
            network: data?.network,
            contractAddress: data?.contractAddress,
            tokenId: data?.tokenId,
            transactionHash: data?.transactionHash,
            marketplaceContractAddress: data?.marketplaceContractAddress,
            referralCode: data?.referralCode,
        }
        const resp = await Api.AuthService.POST(NFT_SERVICE.SAVE_TRANSACTION_NFT, params);
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}
