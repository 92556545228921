import React, { FC } from 'react'
import { Box, Flex, Text, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Modal } from '@chakra-ui/react'
import { useIntl } from 'react-intl'

type Props = {
    openModal: boolean
    onCloseModal: () => void
    timeUnstaking: number
}

export const NotiUnstakingModal: FC<Props> = ({ openModal, onCloseModal, timeUnstaking }) => {
    const intl = useIntl();
    return (
        <Modal isCentered size={'sm'} isOpen={openModal} onClose={onCloseModal}>
            <ModalOverlay />
            <ModalContent alignItems={'center'} bg={'#131619'}>
                <Flex px={'32px'} py={'20px'} w={'100%'} align={'center'} justify={'space-between'}>
                    <Text fontSize={'20px'} fontWeight={700} align={'start'}>{intl.formatMessage({ id: 'NFT.STAKING.MODAL.NOTI.UNSTAKE' })}</Text>
                    <ModalCloseButton />
                </Flex>
                <ModalBody px={'32px'} pb={'52px'} pt={'20px'} >
                    <Box >
                        {intl.formatMessage({ id: 'NFT.STAKING.MODAL.NOTI.UNSTAKE.DESCRIPTION' }, { time: timeUnstaking })}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
