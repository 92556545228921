import { Image, Box, Container, Flex, Icon, Input, Text, Textarea, Avatar, Button, CircularProgress, TagLabel } from '@chakra-ui/react'
import React, { useContext, useEffect, useLayoutEffect } from 'react'
import { WalletContext } from '../wallet/walletContext';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { useIntl } from 'react-intl';
import { fetchUserProfile, updateUserProfile } from '../../components/ProfilePage/userProfileSlice';
import { Link, useLocation } from 'react-router-dom';
import { useAddress } from '@thirdweb-dev/react';
import { SettingSideBar } from '../../components/ProfilePage/SettingSideBar';
import { toast } from 'react-toastify';
import { minify } from '../../helpers/crud-helper/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { isEqual } from 'lodash'

export const AccountSettingPage = () => {
    const intl = useIntl();
    const address = useAddress()
    const location = useLocation();
    const dispatch = useAppDispatch()
    const { isLogin, setOpenModalLogin, tokenExpiredCb } = useContext(WalletContext);
    const { data: userData } = useAppSelector(state => state.userProfile)

    useEffect(() => {
        if (isLogin) return;
        if (!isLogin) {
            setOpenModalLogin(true);
            localStorage.setItem('redirectPath', location?.pathname);
        }
    }, [isLogin]);

    useEffect(() => {
        if (!isLogin) return
        dispatch(fetchUserProfile(tokenExpiredCb))
    }, [isLogin])


    const handleCopy = (data: string) => {
        navigator.clipboard.writeText(data)
        toast.success(intl.formatMessage({ id: 'UTIL.COPIED' }), { closeButton: false, })
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().nullable().notRequired().email().label(intl.formatMessage({ id: 'SETTING.PROFILE.EMAIL' }))
    })

    const initialValues = {
        userName: userData?.fullname,
        email: userData?.email,
        bio: userData?.bio,
        avatar: userData?.avatar,
        banner: userData?.banner
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                if (!isEqual(initialValues, values)) {
                    const result = await dispatch(updateUserProfile({ data: values, tokenExpiredCb }))
                    if (result?.payload?.data) {
                        toast.success(intl.formatMessage({ id: 'SETTING.PROFILE.SUCCESS' }), { closeButton: false, })
                        setSubmitting(false)
                    } else {
                        if (result?.payload?.errors?.[0].message === 'Email already exists') {
                            toast.error(intl.formatMessage({ id: 'SETTING.PROFILE.ERROR.EMAIL.EXISTS' }), { closeButton: false })
                        } else {
                            toast.error(result?.payload?.errors?.[0].message, { closeButton: false })
                        }


                        setSubmitting(false)
                    }
                }
            } catch (error) {
                toast.error('error', { closeButton: false })
                setSubmitting(false)
            }
        },
    })

    const handleAvatarClick = () => {
        const fileInput = document.getElementById('fileAvatarInput');
        fileInput?.click();
    };

    const handleBannerClick = () => {
        const fileInput = document.getElementById('fileBannerInput');
        fileInput?.click();
    };

    const handleImageUploadAvatar = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (!file) return;
        formik.setFieldValue('avatar', file);
    };

    const handleImageUploadBanner = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (!file) return;
        formik.setFieldValue('banner', file);
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container h={isLogin ? 'auto' : '100vh'} maxW={{ xxl: 1400, xl: 1200, lg: 960, md: 768, sm: 480, base: 390 }} margin={'0 auto'} p={0}>
            {isLogin && <>
                <Flex w={'100%'} flexWrap={'wrap'} mt={{ base: '16px', lg: '40px' }}>
                    <Box w={{ lg: '25%', base: '100%' }}>
                        <SettingSideBar />
                    </Box>
                    <Box w={{ lg: '75%', base: '100%' }}>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <Box px={{ base: 0, lg: '72px' }}>
                                <Text mt={{ base: '32px', lg: 0 }} mb={{ base: '32px', lg: '48px' }} fontWeight={700} fontSize={{ base: '24px', lg: '32px' }}>{intl.formatMessage({ id: 'SETTING.PROFILE.TITLE' })}</Text>
                                <Flex flexWrap={'wrap'} mb={{ lg: '48px' }}>
                                    <Box w={{ lg: '50%', base: '100%' }}>
                                        <Box mb={{ base: '24px', lg: '32px' }}>
                                            <Text mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.USERNAME' })}</Text>
                                            <Input
                                                pl={'16px'} border={'none'} focusBorderColor='#29E9DD' h='48px' backgroundColor={'#FFFFFF0A'}
                                                {...formik.getFieldProps('userName')}
                                                name='userName'
                                                type='text'
                                                placeholder={intl.formatMessage({ id: 'SETTING.PROFILE.USERNAME.PLACEHOLDER' })}
                                            />
                                        </Box>
                                        <Box mb={{ base: '24px', lg: '32px' }}>
                                            <Text mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.EMAIL' })}</Text>
                                            <Input
                                                pl={'16px'} border={'none'} focusBorderColor='#29E9DD' h='48px' backgroundColor={'#FFFFFF0A'}
                                                {...formik.getFieldProps('email')}
                                                name='email'
                                                type='text'
                                                placeholder={intl.formatMessage({ id: 'SETTING.PROFILE.EMAIL.PLACEHOLDER' })}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <Box color={'#f1416c'}>
                                                    <span role='alert'>{formik.errors.email}</span>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box mb={{ base: '24px', lg: '32px' }}>
                                            <Text mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.BIO' })}</Text>
                                            <Textarea
                                                pl={'16px'} border={'none'} focusBorderColor='#29E9DD' h='48px' backgroundColor={'#FFFFFF0A'}
                                                {...formik.getFieldProps('bio')}
                                                name='bio'
                                                placeholder={intl.formatMessage({ id: 'SETTING.PROFILE.BIO.PLACEHOLDER' })}
                                            />
                                        </Box>
                                        <Box mb={{ base: '24px', lg: 0 }}>
                                            <Text mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.WALLET.ADDRESS' })}</Text>
                                            <Flex>
                                                <Text fontSize={'16px'} fontWeight={400}>{minify(address as string, 6, 4)}</Text>
                                                <Icon cursor={'pointer'} ml={'10px'} onClick={() => handleCopy(address as string)} display={'block'} h={'14px'} w={'14px'} viewBox="0 0 14 14" fill="none" color="#515458">
                                                    <path
                                                        d="M13.1875 0.25H4.1875C4.03832 0.25 3.89524 0.309263 3.78975 0.414753C3.68426 0.520242 3.625 0.663316 3.625 0.8125V3.625H0.8125C0.663316 3.625 0.520242 3.68426 0.414753 3.78975C0.309263 3.89524 0.25 4.03832 0.25 4.1875V13.1875C0.25 13.3367 0.309263 13.4798 0.414753 13.5852C0.520242 13.6907 0.663316 13.75 0.8125 13.75H9.8125C9.96168 13.75 10.1048 13.6907 10.2102 13.5852C10.3157 13.4798 10.375 13.3367 10.375 13.1875V10.375H13.1875C13.3367 10.375 13.4798 10.3157 13.5852 10.2102C13.6907 10.1048 13.75 9.96168 13.75 9.8125V0.8125C13.75 0.663316 13.6907 0.520242 13.5852 0.414753C13.4798 0.309263 13.3367 0.25 13.1875 0.25ZM9.25 12.625H1.375V4.75H9.25V12.625ZM12.625 9.25H10.375V4.1875C10.375 4.03832 10.3157 3.89524 10.2102 3.78975C10.1048 3.68426 9.96168 3.625 9.8125 3.625H4.75V1.375H12.625V9.25Z"
                                                        fill="#515458"
                                                    />
                                                </Icon>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box pl={{ base: 0, lg: '72px' }} w={{ lg: '50%', base: '100%' }}>
                                        <Box mb={{ base: '24px', lg: '32px' }}>
                                            <Text align={{ base: 'start', lg: 'center' }} mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.IMAGE' })}</Text>
                                            <Flex justify={{ base: 'start', lg: 'center' }} mb={{ base: '16px', lg: '32px' }}>
                                                <input
                                                    id="fileAvatarInput"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageUploadAvatar(e)}
                                                    style={{ display: 'none' }}
                                                />
                                                <Avatar
                                                    crossOrigin="anonymous"
                                                    borderRadius={'50%'}
                                                    w={{ base: '120px', lg: '120px' }}
                                                    h={{ base: '120px', lg: '120px' }}
                                                    objectFit={'contain'}
                                                    onClick={handleAvatarClick}
                                                    cursor={'pointer'}
                                                    src={formik?.values?.avatar ? (typeof formik?.values?.avatar !== 'string' ? URL.createObjectURL(formik?.values?.avatar) : formik?.values?.avatar) : initialValues?.avatar}
                                                />
                                            </Flex>
                                        </Box>
                                        <Box mb={{ base: '24px', lg: '32px' }}>
                                            <Text align={{ base: 'start', lg: 'center' }} mb={{ base: '12px', lg: '12px' }} fontSize={{ lg: '18px', base: '16px' }} fontWeight={700}>{intl.formatMessage({ id: 'SETTING.PROFILE.BANNER' })}</Text>
                                            <Flex justify={{ base: 'start', lg: 'center' }} mb={{ base: '16px', lg: '32px' }}>
                                                <input
                                                    id="fileBannerInput"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageUploadBanner(e)}
                                                    style={{ display: 'none' }}
                                                />
                                                <Image
                                                    crossOrigin="anonymous"
                                                    cursor={'pointer'}
                                                    onClick={handleBannerClick}
                                                    borderRadius={'6px'}
                                                    height={{ base: '120px', lg: '120px' }}
                                                    w={'100%'} h={'inherit'}
                                                    objectFit={'cover'}
                                                    src={formik?.values?.banner ? (typeof formik?.values?.banner !== 'string' ? URL.createObjectURL(formik?.values?.banner) : formik?.values?.banner) : (initialValues?.banner ?? '/images/example/Rectangle.png')} />
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Flex>
                                <Box>
                                    <Button
                                        type='submit'
                                        as={'button'}
                                        color='#FFFFFF'
                                        cursor={'pointer'}
                                        rounded="6px"
                                        fontSize={{ base: '18px', lg: '20px' }}
                                        fontWeight='600'
                                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                        bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                                        p={'2px'}
                                        height={{ base: '48px', lg: '56px' }}
                                        _hover={{ bg: isEqual(initialValues, formik?.values) ? '' : 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                                        _active={{
                                            bg: '#dddfe2',
                                            transform: 'scale(0.98)',
                                            borderColor: '#bec3c9',
                                        }}
                                        disabled={isEqual(initialValues, formik?.values)}
                                        opacity={isEqual(initialValues, formik?.values) ? '30%' : ''}
                                    >
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'100%'} bg="#131619" rounded="4px">
                                            <Box color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>{intl.formatMessage({ id: 'SETTING.PROFILE.SUBMIT' })}
                                                {formik?.isSubmitting && <CircularProgress pl={'10px'} size={'20px'} isIndeterminate color='green.700' />}
                                            </Box>
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Flex>
            </>}
        </Container>
    )
}
