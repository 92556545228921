export function objToQueryString(data: any) {
    if (typeof data != 'object') {
        return data
    }
    const str = []
    for (const p in data) {
        if ((data[p] || data[p] === 0) && Object.prototype.hasOwnProperty.call(data, p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]))
        }
    }
    return str.join('&')
}

export const minify = (address: string, start: number, end: number) => {
    if (!address) return ''
    return `${address.slice(0, start)}...${address.slice(-end)}`
}

export const cleanObject = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== ''))
}

export const openURL = (url: string) => {
    const formatUrl = url.match(/^http[s]?:\/\//i) ? url : 'https://' + url
    window.open(formatUrl)
}

export const formatNumber = (value: number | null | undefined, min: number): string => {
    if (!value) return ''
    if (value === null || value === undefined) return ''
    if (value < min) return `< ${min?.toString()}`
    return value % 1 === 0 ? value.toString() : parseFloat(value.toFixed(4))?.toString()
};

export const formatPositiveNumber = (num: number | null | undefined): number => {
    if (num == null) {
        return 0;
    } else if (Number.isInteger(num) && num > 0) {
        return num;
    } else {
        return parseFloat(num.toFixed(3));
    }
};
