
import { PRICE_SERVICE } from "../../constants";
import { objToQueryString } from "../../helpers/crud-helper/helpers";
import Api from "../axios/SetupAxios"

type PriceUsdReq = {
    'ids': string
    'vs_currencies': string
}

export const getPriceUsdApi = (data: PriceUsdReq) => {
    try {
        const params = {
            ids: data?.ids,
            vs_currencies: data?.vs_currencies
        }
        return Api.PriceService.GET(PRICE_SERVICE?.GET_NFT_PUBLIC + '?' + objToQueryString(params))
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}