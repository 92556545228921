import { ACCESS_TOKEN } from "../../constants"

export const saveAuthorization = async (authorize: any) => {
    authorize?.jwt?.accessToken && localStorage.setItem(ACCESS_TOKEN, authorize?.jwt.accessToken ?? null)
    localStorage.setItem('userData', JSON.stringify(authorize?.user || authorize.data) ?? null)
}

const flushAuthorization = () => {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem('userData')
}

const logout = () => {
    flushAuthorization()
}

export { logout }
