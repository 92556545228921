import { COLLECTION_SERVICE } from "../../constants";
import { objToQueryString } from "../../helpers/crud-helper/helpers";
import { CollectionDetailsReq, CollectionStatisticsType, CollectionType } from "../../models/CollectionType";
import Api from "../axios/SetupAxios"

export const getCollectionDetailPublicApi = async (data: CollectionDetailsReq): Promise<CollectionType> => {
    try {
        const params = {
            ...(data?.network ? { network: data?.network } : {}),
            ...(data?.contractAddress ? { contractAddress: data?.contractAddress } : {})
        }
        const resp: any = await Api.PublicService.GET(COLLECTION_SERVICE.GET_COLLECTION_PUBLIC_DETAIL + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}

export const getCollectionStatisticsPublicApi = async (data: CollectionDetailsReq): Promise<CollectionStatisticsType> => {
    try {
        const params = {
            ...(data?.network ? { network: data?.network } : {}),
            ...(data?.contractAddress ? { contractAddress: data?.contractAddress } : {})
        }
        const resp: any = await Api.PublicService.GET(COLLECTION_SERVICE.GET_COLLECTION_PUBLIC_STATISTICS + '?' + objToQueryString(params));
        return resp?.data
    } catch (error) {
        console.error("Error occurred while fetching collection data:", error);
        throw error
    }
}
