import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import Api from '../../setup/axios/SetupAxios'
import { NFT_SERVICE } from '../../constants'
import { NftType, PaginationType } from '../../models/NftType'
import { cleanObject } from '../../helpers/crud-helper/helpers'

export enum NftStatus {
    ALL = '',
    LISTING = 'listing',
    NEW = 'new',
}

export enum SortType {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortBy {
    PRICE = 'price',
    CREATED_AT = 'createdAt',
}

export const DEFAULT_TAKE = 10
export const DEFAULT_SKIP = 0

type MyNFTReq = {
    take?: number
    skip?: number
    name?: string
    status?: NftStatus
    collectionId?: string
    sortType?: SortType
    sortBy?: SortBy | null
    network?: string
}

const getMyNFTs = (req?: MyNFTReq) => {
    const params = req ? cleanObject(req) : {}
    return Api.NFTService.GET(NFT_SERVICE.GET_MY_NFT, { params: { ...params } })
}

export const getUserNFT = createAsyncThunk('nft/getMyNFTs', async (req?: MyNFTReq) => {
    const resp = await getMyNFTs(req)
    return resp?.data
})

export const resetNFTs = createAction('nft/resetNFTs')

type InitialState = {
    loading: boolean
    data: PaginationType<NftType[]> | null
    errors: any
}

const initialState: InitialState = {
    loading: false,
    data: null,
    errors: null,
}

const userNFTSlice = createSlice({
    name: 'nfts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserNFT.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserNFT.fulfilled, (state, action) => {
            state.loading = false
            const newData = (action.payload?.data as NftType[] || [])?.filter(
                (payloadItem) => !state.data?.data.some((stateItem) => stateItem.id === payloadItem.id)
            )
            state.data = {
                data: [...(state.data?.data || []), ...newData],
                options: action.payload?.options || {},
            }
        })
        builder.addCase(getUserNFT.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.errors = action?.error?.message || 'Something went wrong'
        })
        builder.addCase(resetNFTs, (state, action) => {
            return initialState
        })
    },
})

export default userNFTSlice.reducer
