import { SearchIcon } from '@chakra-ui/icons'
import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'

export const Search = () => {
    const intl = useIntl()
    return (
        <>
            <InputGroup borderRadius={'6px'}>
                <InputLeftElement h={'48px'} w={'48px'} pointerEvents='none'>
                    <Icon h={'15px'} w={'15px'} viewBox='0 0 15 15' color='white'>
                        <path d="M15.1479 14.3519L11.6273 10.8321C12.6477 9.60705 13.1566 8.03577 13.048 6.44512C12.9394 4.85447 12.2217 3.36692 11.0443 2.29193C9.86684 1.21693 8.32029 0.637251 6.72635 0.673476C5.13241 0.709701 3.6138 1.35904 2.48642 2.48642C1.35904 3.6138 0.709701 5.13241 0.673476 6.72635C0.637251 8.32029 1.21693 9.86684 2.29193 11.0443C3.36692 12.2217 4.85447 12.9394 6.44512 13.048C8.03577 13.1566 9.60705 12.6477 10.8321 11.6273L14.3519 15.1479C14.4042 15.2001 14.4663 15.2416 14.5345 15.2699C14.6028 15.2982 14.676 15.3127 14.7499 15.3127C14.8238 15.3127 14.897 15.2982 14.9653 15.2699C15.0336 15.2416 15.0956 15.2001 15.1479 15.1479C15.2001 15.0956 15.2416 15.0336 15.2699 14.9653C15.2982 14.897 15.3127 14.8238 15.3127 14.7499C15.3127 14.676 15.2982 14.6028 15.2699 14.5345C15.2416 14.4663 15.2001 14.4042 15.1479 14.3519ZM1.81242 6.87492C1.81242 5.87365 2.10933 4.89487 2.6656 4.06234C3.22188 3.22982 4.01253 2.58094 4.93758 2.19778C5.86263 1.81461 6.88053 1.71435 7.86256 1.90969C8.84459 2.10503 9.74664 2.58718 10.4546 3.29519C11.1626 4.00319 11.6448 4.90524 11.8401 5.88727C12.0355 6.8693 11.9352 7.8872 11.5521 8.81225C11.1689 9.7373 10.52 10.528 9.68749 11.0842C8.85497 11.6405 7.87618 11.9374 6.87492 11.9374C5.53271 11.9359 4.24591 11.4021 3.29683 10.453C2.34775 9.50392 1.81391 8.21712 1.81242 6.87492Z" fill="#515458" />
                    </Icon>
                </InputLeftElement>
                <Input pl={'48px'} border={'none'} focusBorderColor='#29E9DD' h='48px' backgroundColor={'#FFFFFF0A'} type='text' placeholder={intl.formatMessage({ id: 'HEADER.SEARCH.PLACEHOLDER' })} />
            </InputGroup>
        </>
    )
}
