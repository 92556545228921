import { COLLECTION_SERVICE } from '../../constants'
import { objToQueryString } from '../../helpers/crud-helper/helpers'
import { CollectionType } from '../../models/CollectionType'
import Api from '../axios/SetupAxios'

interface Options {
    totalItems: number
    totalPages: number
    currentPage: number
}

interface DataResponse {
    data: CollectionType[]
    options: Options
}

interface ApiResponse {
    data: DataResponse
    errors: null
}

type CollectionReq = {
    take?: number
    skip?: number
    isStaking?: boolean
    timeInterval?: string
    categorySlug?: string
    network?: string
    walletAddress?: string
    isSlider?: boolean
}

export const getCollectionPublicApi = async (data: CollectionReq): Promise<DataResponse> => {
    try {
        const params = {
            ...(data?.take ? { take: data?.take } : {}),
            ...(data?.skip ? { skip: data?.skip } : {}),
            ...(data?.isStaking ? { isStaking: data?.isStaking } : {}),
            ...(data?.timeInterval ? { timeInterval: data?.timeInterval } : {}),
            ...(data?.categorySlug ? { categorySlug: data?.categorySlug } : {}),
            ...(data?.network ? { network: data?.network } : {}),
            ...(data?.walletAddress ? { walletAddress: data?.walletAddress } : {}),
            ...(data?.isSlider ? { isSlider: data?.isSlider } : {}),
        }
        const resp: ApiResponse = await Api.CollectionService.GET(COLLECTION_SERVICE.GET_COLLECTION_PUBLIC + '?' + objToQueryString(params))
        return resp?.data
    } catch (error) {
        console.error('Error occurred while fetching collection data:', error)
        throw error
    }
}
