import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, Image, Box, Icon, Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { NftDetailType } from '../../models/NftType'
import { URL_COLLECTION_DEFAULT } from '../../constants'

type Props = {
    openModalViewListing: boolean
    onCloseModalViewListing: () => void
    nftInformation?: NftDetailType
}

export const ViewListingModal: FC<Props> = ({ openModalViewListing, onCloseModalViewListing, nftInformation }) => {
    const intl = useIntl();
    return (
        <Modal isCentered size={'md'} isOpen={openModalViewListing} onClose={onCloseModalViewListing}>
            <ModalOverlay />
            <ModalContent alignItems={'center'} bg={'#131619'}>
                <Flex px={'32px'} py={'20px'} w={'100%'} align={'center'} justify={'end'}>
                    <ModalCloseButton />
                </Flex>
                <ModalBody px={'32px'} w={'100%'}>
                    <Flex py={{ base: '24px', lg: '28px' }} w={{ lg: '100%%', base: '100%' }} justify={'center'} align={'center'} >
                        <Image
                            borderRadius={'6px'}
                            h={{ base: '160px', lg: '160px' }}
                            w={{ base: '160px', lg: '160px' }}
                            crossOrigin="anonymous"
                            objectFit={'contain'}
                            src={nftInformation?.image || URL_COLLECTION_DEFAULT}
                        />
                    </Flex>
                    <Flex mb={{ base: '24px', lg: '32px' }} flexWrap={'wrap'} justify={{ lg: 'center', base: 'center' }} align={'center'}>
                        <Box fontWeight={700} fontSize={{ lg: '28px', base: '24px' }} mb={{ base: '12px', lg: '16px' }}>{intl.formatMessage({ id: 'NFT.SALE.VIEW.LISTING' })}</Box>
                        <Box w={'100%'} textAlign={{ base: 'center', lg: 'center' }} fontWeight={400} fontSize={'16px'}>
                            {intl.formatMessage({ id: 'NFT.SALE.VIEW.LISTING.DESCRIPTION' }, { nftName: <span style={{ color: '#29E9DD' }} >{nftInformation?.name || `#${nftInformation?.tokenId}`}</span>, collectionName: <span style={{ color: '#29E9DD' }} >{nftInformation?.collection?.name}</span> })}
                        </Box>
                    </Flex>
                    <Box mb={{ base: '24px', lg: '32px' }}>
                        <Button
                            w={'100%'}
                            as={'button'}
                            color='#FFFFFF'
                            cursor={'pointer'}
                            rounded="6px"
                            fontSize={{ base: '18px', lg: '18px' }}
                            fontWeight='600'
                            transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                            bg={'linear-gradient(90deg, #29E9DD, #F99FAB)'}
                            p={'2px'}
                            height={{ base: '48px', lg: '48px' }}
                            _hover={{ bg: 'linear-gradient(90deg, #F99FAB, #29E9DD)', transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)' }}

                            _active={{
                                bg: '#dddfe2',
                                transform: 'scale(0.98)',
                                borderColor: '#bec3c9',
                            }}
                            onClick={onCloseModalViewListing}
                        >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'} bg="#131619" rounded="4px">
                                <Box w={'100%'} color={'#FFFFFF'} style={{ textWrap: 'nowrap' }} display='flex' alignItems={'center'} justifyContent={'center'} px={{ base: '12px', lg: '24px' }} h={'100%'} textAlign={'center'} bgColor={'#FFFFFF0A'}>
                                    {intl.formatMessage({ id: 'NFT.SALE.BUTTON.VIEW.LISTING' })}
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
